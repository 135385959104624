
import Image from './image'

import MenuObject from './menuobject'

import mixer5 from '../shader/mixer5.glsl'


export default class Logo extends MenuObject {

	constructor(menu, logo, back_texture) {
		super(menu)

		const textures = logo.map(item => item.image.texture)

		this.image = new Image(menu, textures, [back_texture], mixer5)

		this.image.material.uniforms.text_index0.value = this.menu.isSP ? 1 : 0
		
		this.object.add(this.image.mesh)
	}

	setSize(cw, ch, cr, bw) {
		super.setSize(cw, ch, cr, bw)

		const size = Math.max(cw, ch)

		this.image.material.uniforms.text_index0.value = this.menu.isSP ? 1 : 0

		this.image.mesh.scale.x = cw
		this.image.mesh.scale.y = (this.menu.isSP ? 200 / 750 : 170 / 1400) * bw

		this.image.material.uniforms.text_mapping_ratio.value.x = cw / bw
		this.image.material.uniforms.text_mapping_ratio.value.y = 1.0

		this.image.material.uniforms.back_mapping_ratio.value.x = cw / size
		this.image.material.uniforms.back_mapping_ratio.value.y = this.image.mesh.scale.y / size

		this.image.material.uniforms.back_mapping_offset.value.x = - (cw / 2 - size / 2) / size
	}

	draw(y) {
		this.object.position.y = this.canvasH / 2 - y - this.image.mesh.scale.y / 2
	}
}
