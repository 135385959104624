
import {
	SCROLL_TOP,
	SCROLL_TO
} from './types'

const initialState = {
	top : 0
}


export default function scrollReducer(state = initialState, action) {

	switch (action.type) {
		case SCROLL_TOP:
			return {...state, top:0}

		case SCROLL_TO:
			return {...state, top:action.top}

		default:
			return state

	}
}