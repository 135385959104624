
import React from 'react'

import styles from './index.module.scss'


const Title = (props) => (
	<React.Fragment>
		{props.payload.title_image_pc && (
		<h1 className={styles.title}>
			<div>
				{props.device === 'sp' ? (
					<img src={props.payload.title_image_sp.src} alt={props.payload.title_image_sp.alt} />
				) : (
					<img src={props.payload.title_image_pc.src} alt={props.payload.title_image_pc.alt} />
				)}
			</div>
		</h1>
		)}
	</React.Fragment>
)

export default Title