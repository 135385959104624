
import React from 'react'

import $ from 'jquery'

import Polaris from 'lib/Polaris'


class Scrollin extends React.Component {

	ref = React.createRef()

	state = {
		hidden : true
	}

	componentDidMount() {

		this.$el = $(this.ref.current)

		this.scrollID = Polaris.util.onScroll((t, b) => {

			const offset = this.$el.offset().top
			const height = this.$el.outerHeight(true)

			if (Polaris.util.visibleAreaRate(offset, height, t, b) > this.props.rate) {
				this.setState({hidden:false})
				this.dispose()
			}
		})
	}

	componentWillUnmount() {
		this.dispose()
	}

	dispose() {
		Polaris.util.offScroll(this.scrollID)
	}

	render(child) {
		return (
			<div ref={this.ref}>
				{this.props.render(this.state.hidden)}
			</div>
		)
	}
}

export default Scrollin