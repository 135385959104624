
import React from 'react'

import { connect } from 'react-redux'


class MenuReady extends React.Component {

	state = {
		ready : false
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.ready !== prevProps.ready && this.props.ready) {
			this.setState({ready:true})
		}
	}

	render() {
		if (this.state.ready) {
			return this.props.render()
		} else {
			return null
		}
	}
}

function mapStateToProps(state) {
	return {
		ready : state.menu.ready,
	}
}

export default connect(mapStateToProps)(MenuReady)