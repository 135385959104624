
import React from 'react'

import { Section, Container, Inner } from 'views/components/common/section/index'

import styles from './index.module.scss'


const Information = (props) => (
	<Section className={styles.information}>
		<Container>
			<Inner>
				{props.payload.information.title && (
					<h2>{props.payload.information.title}</h2>
				)}

				{props.payload.information.text && (
					<div className={styles.text} dangerouslySetInnerHTML={{__html:props.payload.information.text}}></div>
				)}

				<div className={styles.image}>
					{props.device === 'sp' ? (
						<img src={props.payload.information.image_sp.src} alt={props.payload.information.image_sp.alt} />
					) : (
						<img src={props.payload.information.image_pc.src} alt={props.payload.information.image_pc.alt} />
					)}
				</div>
			</Inner>
		</Container>
	</Section>
)

export default Information