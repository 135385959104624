
import React from 'react'

import { connect } from 'react-redux'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import Scrollin from 'helpers/scrollin'

import Link from './link'

import styles from './slide.module.scss'


class Slide extends React.Component {

	slide = React.createRef()
	mover = React.createRef()

	state = {
		prevActive : false,
		nextActive : true
	}

	set moverX(val) {
		this._x = val

		if (this.mover.current) {
			this.mover.current.style.transform = `translate3d(${this._x}px, 0, 0)`
		}

		const prevActive = this.limitX !== 0 && this._x < 0
		const nextActive = this.limitX !== 0 && this._x > this.limitX

		if (prevActive !== this.state.prevActive) this.setState({prevActive:prevActive})
		if (nextActive !== this.state.nextActive) this.setState({nextActive:nextActive})
	}

	get moverX() {
		return this._x || 0
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.finish !== prevProps.finish && this.props.finish) {
			this.ready()
		}
	}

	componentWillUnmount() {
		this.dispose()
	}

	ready() {
		let tx = null, ty, dx, dy, scroll

		this.$mover = $(this.mover.current)

		this.resizeID = Polaris.util.onResize(() => {
			this.resized()
		})

		this.$mover.on('click', 'a', (e) => {
			if (this.dragging) e.preventDefault()
		})

		this.$mover.on({

			'touchstart mousedown' : (e) => {
				Polaris.util.offFrame(this.frameID)
				tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
				ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY
				dx = 0
				dy = 0
				scroll = null
			},

			'touchmove mousemove' : (e) => {
				if (tx !== null) {
					const _tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
					const _ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY

					dx = _tx - tx;
					dy = _ty - ty;
					tx = _tx;
					ty = _ty;

					if (scroll === null) {
						scroll = Math.abs(dy) > Math.abs(dx)

						if (scroll) {
							tx = null
							ty = null
						} else {
							e.preventDefault()
							this.moverX += dx
							this.dragging = true
						}
					} else {
						e.preventDefault()
						this.moverX += dx
					}
				}
			},

			'touchend touchcancel mouseup mouseleave' : (e) => {
				if (tx !== null) {
					this.frameID = Polaris.util.onFrame((ct, dt, pt) => {

						const timeScale = Math.min(dt, 50) / (1000 / 60)

						let outBounds = false

						this.moverX += (dx *= Math.min(0.99, 0.95 * timeScale))

						if (this.moverX > 0) {
							outBounds = true
							this.moverX += (0 - this.moverX) * 0.2 * timeScale
						}

						if (this.moverX < this.limitX) {
							outBounds = true
							this.moverX += (this.limitX - this.moverX) * 0.2 * timeScale
						}

						if (!outBounds && Math.abs(dx) < 0.01) return false
					})

					setTimeout(() => {
						this.dragging = false
					})

					tx = null
					ty = null
				}
			}
		})

		this.moverX = 0
	}

	prev(e) {
		this.change(-1);
	}

	next (e) {
		this.change(+1);
	}

	resized() {
		if (this.mover.current) {
			this.width = [].reduce.call(this.mover.current.getElementsByTagName('li'), (w, li, i) => {
				return w + $(li).outerWidth(true)
			}, 0)

			this.limitX = Math.min(0, this.slide.current.clientWidth - this.width)
		}
	}

	change(step) {

		Polaris.util.offFrame(this.frameID)

		const $li = this.$mover.find('li').eq(0)

		const x = Polaris.util.clamp(this.moverX - step * $li.outerWidth(true), this.limitX, 0)

		$(this).stop().animate({moverX:x}, 450, 'ioX4')
	}

	dispose() {
		Polaris.util.offResize(this.resizeID)

		if (this.$mover) {
			this.$mover.off('*')
		}
	}

	render() {
		return (
			<Scrollin rate={0.7} render={(hidden) => (
				<div ref={this.slide} className={styles.slide} aria-hidden={hidden}>

					<ul ref={this.mover} className={styles.mover}>
						{this.props.items.map((item, j) => (
							<li key={j} className={styles.item}>
								<Link type={item.type} href={item.link} image={item.image} />
							</li>
						))}
					</ul>

					<button className={styles.prev} onClick={this.prev.bind(this)} aria-hidden={!this.state.prevActive}>
						<img src="/assets/img/arrow-l.svg" alt="PREV" />
					</button>
					<button className={styles.next} onClick={this.next.bind(this)} aria-hidden={!this.state.nextActive}>
						<img src="/assets/img/arrow-r.svg" alt="NEXT" />
					</button>
				</div>
			)} />
		)
	}
}

function mapStateToProps(state) {
	return {
		finish : state.menu.finish
	}
}

export default connect(mapStateToProps)(Slide)