
import React from 'react'

import { NavLink } from 'react-router-dom'

import { useDispatch } from 'react-redux'

import actions from 'states/notice/actions'

import styles from './item.module.scss'


const Item = (props) => {

	const dispatch = useDispatch()

	const style = {
		top    : props.y * 100 + 25 + 'vh',
		left   : props.x * 100 + 'vw',
		zIndex : props.z,
	}

	return (
		<li className={styles.item} style={style} aria-hidden={props.closed}>
			{props.type === '1' ? (
				<NavLink exact to={props.link}>{props.children}</NavLink>
			) : (
				<a href={props.link} target="_blank" rel="noopener noreferrer">{props.children}</a>
			)}

			<button onClick={() => dispatch(actions.close(props.index))} />
		</li>
	)
}

export default Item