
import { delay, call, put, all, takeLatest } from 'redux-saga/effects'

import { FETCH_PAGE } from './types'

import actions from './actions'

import fetch from './api'


function* fetchPage(action) {

	yield put(actions.start())

	const load = call(fetch, action.path, action.query)

	const wait = delay(720)

	const { result } = yield all({result:load, wait:wait})

	const { payload, error } = result

	if (payload) {
		yield put(actions.success(payload))
	} else {
		yield put(actions.failed(error))
	}
}


export default [
	takeLatest(FETCH_PAGE, fetchPage)
]