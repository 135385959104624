
import * as THREE from 'three'

import { TimelineMax, TweenMax, Power2 } from 'gsap'

import Polaris from 'lib/Polaris'

import glaze_vert from './shaders/glaze_vert.glsl'
import glaze_frag from './shaders/glaze_frag.glsl'


export default class ImageObject {

	constructor(data) {
		this.isSP = false
		this.src  = data.image.src
		this.size = parseInt(data.size)

		this.visible = false
		this.imageX  = 0
		this.imageY  = 0
		this.imageW  = 0
		this.imageH  = 0
		this.marginL = 0
		this.marginT = 0
		this.targetT = 0

		this.speed = Polaris.util.rand(15, 50) * 0.01
		this.dy    = null
		this.ty    = null

		this.showTimeline = new TimelineMax()

		this.scaleTimeline = new TimelineMax()

		this.mesh = new THREE.Mesh(new THREE.PlaneGeometry(), new THREE.ShaderMaterial({
			uniforms : {
				size    : { value : [1, 1] },
				seed    : { value : Math.random() },
				time    : { value : 0.0 },
				opacity : { value : 0.0 }
			},
			vertexShader   : glaze_vert,
			fragmentShader : glaze_frag,
			transparent    : true
		}))

		this.object = new THREE.Object3D()
		this.object.position.z = -9999
		this.object.scale.x    = 0.0001
		this.object.scale.y    = 0.0001
	}


	load() {
		return new Promise((resolve, reject) => {
			const img = new Image()

			img.onload = () => {
				this.texture = new THREE.Texture(img)
				this.texture.needsUpdate = true
				this.mesh.material.uniforms.texture = { value:this.texture }
				this.mesh.material.needsUpdate = true
				resolve()
			}
			img.crossOrigin = true
			img.src = this.src
		})
	}


	show() {
		this.visible = true

		this.object.add(this.mesh)

		Promise.all([this.showBorder(), this.load()]).then(() => {
			this.showTimeline.kill()
			this.showTimeline.clear()

			const queue = []

			queue.push(TweenMax.to(this.mesh.material.uniforms.opacity, 2.0, {
				ease  : Power2.easeOut,
				value : 1
			}))

			queue.push(TweenMax.to(this.mesh.material.uniforms.time, 2.0, {
				ease  : Power2.easeOut,
				value : 1,
			}))

			this.showTimeline.add(queue)
		})
	}


	hide() {
		this.showTimeline.kill()
		this.showTimeline.clear()
		this.visible = false
		this.object.scale.x = 0.0001
		this.object.scale.y = 0.0001
		this.mesh.material.uniforms.time.value = 0
		this.mesh.material.uniforms.opacity.value = 0
	}


	showBorder() {
		return new Promise((resolve) => {
			this.showTimeline.add(TweenMax.to(this.object.scale, 1.0, {x:1.0, y:1.0, ease:Power2.easeOut})).call(resolve)
		})
	}


	scaleUp() {
		this.scaleTimeline.kill()
		this.scaleTimeline.clear()
		this.scaleTimeline.add(TweenMax.to(this.object.scale, 1.0, {x:1.05, y:1.05, ease:Power2.easeOut}))
	}

	scaleDown() {
		this.scaleTimeline.kill()
		this.scaleTimeline.clear()
		this.scaleTimeline.add(TweenMax.to(this.object.scale, 1.0, {x:1.0, y:1.0, ease:Power2.easeOut}))
	}


	resize(canvasW, canvasH, resolution, prevY) {
		this.canvasW = canvasW
		this.canvasH = canvasH

		switch (this.size) {
			case 1:
				this.imageW = (this.isSP ? 445 / 640 : 745 / 1400) * this.canvasW
				this.imageH = (this.isSP ? 314 / 640 : 526 / 1400) * this.canvasW
				break
			case 2:
				this.imageW = (this.isSP ? 314 / 640 : 526 / 1400) * this.canvasW
				this.imageH = (this.isSP ? 445 / 640 : 745 / 1400) * this.canvasW
				break
			case 3:
				this.imageW = (this.isSP ? 580 / 640 : 1105 / 1400) * this.canvasW
				this.imageH = (this.isSP ? 409 / 640 :  780 / 1400) * this.canvasW
				break
			case 4:
				this.imageW = (this.isSP ? 409 / 640 :  780 / 1400) * this.canvasW
				this.imageH = (this.isSP ? 580 / 640 : 1105 / 1400) * this.canvasW
				break
			default:
		}

		this.mesh.material.uniforms.size.value[0] = this.imageW / resolution
		this.mesh.material.uniforms.size.value[1] = this.imageH / resolution

		this.mesh.scale.x = this.imageW
		this.mesh.scale.y = this.imageH
		this.mesh.position.y = - this.imageH / 2

		this.imageX = this.marginL * this.canvasW
		this.imageY = this.marginT * this.imageH + prevY

		return this.imageY + this.imageH
	}


	destroy() {
		this.showTimeline.kill()
		this.showTimeline.clear()
		this.scaleTimeline.kill()
		this.scaleTimeline.clear()

		if (this.texture) {
			this.texture.dispose()
		}

		this.mesh.geometry.dispose()
		this.mesh.material.dispose()
	}


	scroll(scrollTop, offsetTop) {
		this.scrollTop = scrollTop
		this.offsetTop = offsetTop

		const max = this.canvasW * 0.5

		this.dy = Polaris.util.clamp((this.imageY - this.scrollTop) * this.speed, -max, +max)

		if (this.ty === null) {
			this.ty = this.dy
		}
	}

	update(dt) {

		this.ty += (this.dy - this.ty) * Math.min(dt, 50) * 0.002

		this.object.position.x = this.imageX
		this.object.position.y = this.canvasH / 2 + this.offsetTop - this.imageY - this.ty

		const minY = this.imageY + this.ty - this.offsetTop
		const maxY = minY + this.imageH

		this.object.visible = !(minY > this.canvasH || maxY < 0)

		if (!this.visible && this.object.visible) {
			this.show()
		}
		// if (this.visible && !this.object.visible) {
		// 	this.hide()
		// }
	}
}