
import React from 'react'

import Exhibition from 'lib/exhibition1/index'

import Item from './item'

import styles from './index.module.scss'


class Type1 extends React.Component {

	ul = React.createRef()

	componentDidMount() {
		this.exhibition = new Exhibition(this.ul.current, this.props.items)
		this.exhibition.start()
	}

	componentWillUnmount() {
		this.exhibition.destroy()
		this.exhibition = null
	}

	render() {
		return (
			<div className={styles.list}>
				<ul ref={this.ul}>
					{this.props.items.map((item, i) => (
						<Item key={i} index={i} {...item} />
					))}
				</ul>
			</div>
		)
	}
}

export default Type1