
import React from 'react'

import { connect } from 'react-redux'

import $ from 'jquery'

import actions from 'states/images/actions'

import Exhibition from 'lib/exhibition2/index'

import styles from './index.module.scss'


class Type2 extends React.Component {

	wrapper = React.createRef()

	state = {
		hover : false
	}


	componentDidMount() {
		this.exhibition = new Exhibition(this.wrapper.current, this.props.items, this.props.device)

		$(this.wrapper.current).on('mousemove', (e) => {
			if (this.exhibition.hover(e.offsetX, e.offsetY) !== -1) {
				this.setState({hover:true})
			} else {
				this.setState({hover:false})
			}
		})

		$(this.wrapper.current).on('mouseleave', (e) => {
			this.exhibition.hover(-1, -1)
			this.setState({hover:false})
		})

		$(this.wrapper.current).on('click', (e) => {
			const index = this.exhibition.click(e.offsetX, e.offsetY)

			if (index !== -1) {
				this.props.openModal(index)
			}
		})
	}

	componentDidUpdate(prevProps) {
		if (this.props.device !== prevProps.device) {
			this.exhibition.device = this.props.device
		}
	}

	componentWillUnmount() {
		this.exhibition.destroy()
		this.exhibition = null
	}

	render() {
		return (
			<div className={styles.list} data-state-hover={this.state.hover}>
				<div className={styles.sticky}>
					<div className={styles.wrapper} ref={this.wrapper}></div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		device : state.device.mode,
		ready  : state.menu.ready,
		finish : state.menu.finish
	}
}

function mapDispatchToProps(dispatch) {
	return {
		openModal : (index) => dispatch(actions.openModal(index))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Type2)