
import {
	MENU_CHANGE,
	MENU_LOADED,
	MENU_READY,
	MENU_FINISH,
	MENU_CLOSE,
} from './types'


const initialState = {
	pIndex : -1,
	cIndex : -1,
	loaded : false,
	ready  : false,
	finish : false,
}


export default function menuReducer(state = initialState, action) {

	switch (action.type) {
		case MENU_CHANGE:
			return {...state, loaded:false, ready:false, finish:false, pIndex:action.pIndex, cIndex:action.cIndex}

		case MENU_LOADED:
			return {...state, loaded:true}

		case MENU_READY:
			return {...state, ready:true}

		case MENU_FINISH:
			return {...state, finish:true}

		case MENU_CLOSE:
			return {...state, pIndex:-1, cIndex:-1}

		default:
			return state

	}
}