
import React from 'react'

import { connect } from 'react-redux'

import styles from './html.module.scss'


class Html extends React.Component {

	componentDidUpdate() {

		const classNames = [];

		if (this.props.openModal) {
			classNames.push(styles.openModal)
		}

		document.documentElement.className = classNames.join(' ')

		// モーダルを開いたときにスクロールバーによるがたつきが出ないよう調整
		document.body.style.marginRight = this.props.openModal ? window.scrollBarWidth : ''
	}

	render() {
		return (
			<React.Fragment>
				{this.props.children}
			</React.Fragment>
		)
	}
}

function mapStateToProps(state, props) {
	return {
		openModal : state.images.open
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Html)