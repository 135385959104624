
import { call, put, take, takeLatest } from 'redux-saga/effects'

import { eventChannel } from 'redux-saga'

import { LOADER_START } from './types'

import * as THREE from 'three'

import actions from './actions'

import getMenu from './api'


function* startLoading() {

	const { payload, error } = yield call(getMenu)

	if (payload) {
		const channel = yield call(waitLoading, payload)

		while (true) {
			let action = yield take(channel)
			yield put(action)
		}
	} else {
		put(actions.failed(error))
	}
}

function waitLoading(payload) {

	return eventChannel((emit) => {

		const promises = []

		let total = 0

		const loaded = {}


		payload.menu.forEach((item) => {
			if (item.menu_image) {
				total += item.menu_image.size
				loaded[item.menu_image.src] = 0
			}
			if (item.back_image) {
				total += item.back_image.size
				loaded[item.back_image.src] = 0
			}
			if (item.main_image_pc) {
				total += item.main_image_pc.size
				loaded[item.main_image_pc.src] = 0
			}
			if (item.main_image_sp) {
				total += item.main_image_sp.size
				loaded[item.main_image_sp.src] = 0
			}

			item.children && item.children.forEach((child) => {
				if (child.menu_image) {
					total += child.menu_image.size
					loaded[child.menu_image.src] = 0
				}
				if (child.back_image) {
					total += child.back_image.size
					loaded[child.back_image.src] = 0
				}
				if (child.main_image_pc) {
					total += child.main_image_pc.size
					loaded[child.main_image_pc.src] = 0
				}
				if (child.main_image_sp) {
					total += child.main_image_sp.size
					loaded[child.main_image_sp.src] = 0
				}
			})
		})

		payload.notices.forEach((notice) => {
			if (notice.notice_image_pc) {
				total += notice.notice_image_pc.size
				loaded[notice.notice_image_pc.src] = 0
			}
			if (notice.notice_image_sp) {
				total += notice.notice_image_sp.size
				loaded[notice.notice_image_sp.src] = 0
			}
		})

		payload.back.forEach((back) => {
			if (back.back_image) {
				total += back.back_image.size
				loaded[back.back_image.src] = 0
			}
		})

		payload.logo = [
			{ image: { src : '/assets/img/logo_texture@pc.png', size:0 } },
			{ image: { src : '/assets/img/logo_texture@sp.png', size:0 } }
		]


		function progress(src, val) {

			loaded[src] = val

			const current = Object.values(loaded).reduce((carry, val) => {
				return carry + val
			}, 0)

			emit(actions.progress(current/total))
		}

		payload.menu.forEach((item) => {
			if (item.menu_image) promises.push(loadTexture(item.menu_image, progress))
			if (item.back_image) promises.push(loadTexture(item.back_image, progress, true))
			if (item.main_image_pc) promises.push(loadImage(item.main_image_pc, progress))
			if (item.main_image_sp) promises.push(loadImage(item.main_image_sp, progress))

			item.children && item.children.forEach((child) => {
				if (child.menu_image) promises.push(loadTexture(child.menu_image, progress))
				if (child.back_image) promises.push(loadTexture(child.back_image, progress, true))
				if (child.main_image_pc) promises.push(loadImage(child.main_image_pc, progress))
				if (child.main_image_sp) promises.push(loadImage(child.main_image_sp, progress))
			})
		})

		payload.notices.forEach((notice) => {
			if (notice.notice_image_pc) promises.push(loadTexture(notice.notice_image_pc, progress, true))
			if (notice.notice_image_sp) promises.push(loadTexture(notice.notice_image_pc, progress, true))
		})

		payload.back.forEach((back) => {
			if (back.back_image) promises.push(loadTexture(back.back_image, progress, true))
		})

		payload.logo.forEach((logo) => {
			promises.push(loadTexture(logo.image, progress, true))
		})
		

		Promise.all(promises).then(() => {
			emit(actions.success(payload))
		}).catch(() => {
			emit(actions.failed())
		})

		// unsubscribe
		return function() {
			;
		}
	})
}

function loadTexture(data, progress, light=false) {
	return new Promise((resolve, reject) => {

		const img = new Image()

		img.onload = function() {
			progress(data.src, data.size)

			data.texture = new THREE.Texture(img)

			if (light) {
				data.texture.magFilter = THREE.NearestFilter
				data.texture.minFilter = THREE.LinearFilter
				data.texture.generateMipmaps = false
			}
			
			data.texture.needsUpdate = true
			
			resolve()
		}

		img.onerror = function() {
			progress(data.src, data.size)
			data.texture = new THREE.Texture()
			resolve()
		}

		img.crossOrigin = true
		img.src = data.src
	})
}

function loadImage(data, progress) {
	return new Promise((resolve, reject) => {

		const img = new Image()

		img.onload = function() {
			progress(data.src, data.size)
			data.img = img
			resolve()
		}

		img.onerror = function() {
			progress(data.src, data.size)
			data.img = img
			resolve()
		}

		img.crossOrigin = true
		img.src = data.src
	})
}

export default [
	takeLatest(LOADER_START, startLoading)
]