
import * as THREE from 'three'

export default class MenuObject {

	object = new THREE.Object3D()


	constructor(menu) {
		this.menu = menu
		this.menu.layer1.add(this.object)
	}

	setSize(cw, ch, cr, bw) {
		this.canvasW = cw
		this.canvasH = ch
		this.canvasR = cr
	}
	
	set visible(val) {
		this.object.visible = val
	}

	get visible() {
		return this.object.visible
	}

	get resolution() {
		return this.menu.resolution
	}

	get baseWidth() {
		return this.menu.baseWidth
	}

	get zIndex() {
		return this.object.position.z
	}

	set zIndex(val) {
		this.object.position.z = val
	}
}