
import React from 'react'

import { Section, Container, Title } from 'views/components/common/section/index'

import Slide from './slide'

import styles from './index.module.scss'


const Movie = (props) => (

	<Section className={styles.movie}>
		<Container className={styles.container}>
		
			<Title srcSP="/assets/img/movie@sp.png" srcPC="/assets/img/movie@pc.png" alt="MOVIE" />
				
			<div className={styles.content}>
				<Slide items={props.payload.movies} />
			</div>

		</Container>
	</Section>
)

export default Movie