
import noise from '../../shader/noise.glsl'

export default `

uniform float     time;
uniform float     seed;
uniform float     opacity;
uniform sampler2D texture;

varying vec2 vUv;

${noise}


void main() {
	if (time == 0.0) {
		;
	} else if (time == 1.0) {
		gl_FragColor = texture2D(texture, vUv);
	} else {
		float z = getNoise2(vUv + seed);
		float c = 2.0 * z * z * z * z;
		float q = c * (1.0 - time);

		vec4 color = texture2D(texture, vec2(vUv.x, vUv.y - q));

		color.w = opacity;

		gl_FragColor = color;
	}
}

`