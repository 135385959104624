
import React from 'react'

import Polaris from 'lib/Polaris'

import { Section, Container, Title } from 'views/components/common/section/index'

import Type1 from './type1/index'
import Type2 from './type2/index'
import Modal from './modal/index'

import styles from './index.module.scss'


const Exhibition = (props) => (
	<Section className={styles.exhibition}>
		<Container>

			{props.hasTitle && (
			<Title
				srcSP="/assets/img/subtitle-images@sp.png"
				srcPC="/assets/img/subtitle-images@pc.png"
				alt="EXHIBITION/PROCESS" />
			)}

			{Polaris.ua.ie || Polaris.ua.mobile ? (
				<Type1 items={props.payload.exhibition} />
			) : (
				<Type2 items={props.payload.exhibition} />
			)}

			<Modal items={props.payload.exhibition} />

		</Container>
	</Section>
)

export default Exhibition