
import React from 'react'

import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import pageActions from 'states/page/actions'


export default function withLoading(Component) {

	class WithLoadingComponent extends React.Component {

		componentDidMount() {
			this.fetch()
		}

		componentDidUpdate(prevProps, prevState) {
			const nextPath = this.props.router.location.pathname
			const prevPath =  prevProps.router.location.pathname

			if (nextPath !== prevPath) {
				if (nextPath === this.props.location.pathname) {
					this.fetch()
				}
			}
		}

		componentWillUnmount() {
		}

		fetch() {
			this.props.fetch(
				this.props.router.location.pathname,
				this.props.router.location.search,
				this.props.router.action
			)
		}

		render() {
			return <Component {...this.props} />
		}
	}

	function mapStateToProps(state) {
		return {
			router  : state.router,
			device  : state.device.mode,
			status  : state.page.status,
			payload : state.page.payload,
			error   : state.page.error,
		}
	}

	function mapDispatchToProps(dispatch) {
		return {
			fetch : function(path, query, reason) {
				dispatch(pageActions.fetch(path, query, reason))
			}
		}
	}

	return withRouter(connect(mapStateToProps, mapDispatchToProps)(WithLoadingComponent))
}