
import React from 'react'

import Player from '@vimeo/player'

import styles from './index.module.scss'


class MainVideo extends React.Component {

	wrapper = React.createRef()

	state = {
		volume : 0
	}


	componentDidMount() {

		this.player = new Player(this.wrapper.current, {
			id       : this.props.payload.main_video_id,
			muted    : true,
			controls : false,
			autoplay : true,
			loop     : true,
			playsinline : true
		})

		this.player.ready().then(() => {
			this.player.setMuted(true)
		})
	}

	componentWillUnmount() {
		if (this.player) {
			this.player.destroy()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.volume !== prevState.volume) {
			this.player.setVolume(this.state.volume > 0 ? 1.0 / Math.pow(2, 4 - this.state.volume) : 0)
		}
	}

	updateVolume() {
		this.setState({
			volume : (this.state.volume + 1) % 5
		})
	}

	render() {
		return (
			<div className={styles.mainvideo}>
				<div ref={this.wrapper} className={styles.wrapper}></div>

				<button onClick={this.updateVolume.bind(this)} data-state-volume={this.state.volume}>
					<span className={styles.bar1}></span>
					<span className={styles.bar2}></span>
					<span className={styles.bar3}></span>
					<span className={styles.bar4}></span>
				</button>
			</div>
		)
	}
}

export default MainVideo
