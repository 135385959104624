
import {
	FETCH_PAGE,
	FETCH_PAGE_START,
	FETCH_PAGE_SUCCESS,
	FETCH_PAGE_FAILED
} from './types'


export const fetch = function(path, query, reason) {
	return {type:FETCH_PAGE, path:path, query:query, reason:reason}
}

export const start = function() {
	return {type:FETCH_PAGE_START}
}

export const success = function(payload) {
	return {type:FETCH_PAGE_SUCCESS, payload:payload}
}

export const failed = function(error) {
	return {type:FETCH_PAGE_FAILED, error:error}
}

export default {
	fetch   : fetch,
	start   : start,
	success : success,
	failed  : failed
}