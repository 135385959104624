
import * as THREE from 'three'

import Polaris    from 'lib/Polaris'

import MenuObject from './menuobject'


export default class LineCursor extends MenuObject {

	_y = -1

	constructor(menu) {
		super(menu)

		this.geometry = new THREE.PlaneGeometry()

		this.material = new THREE.MeshBasicMaterial({color:0x000000})
		
		this.mesh = new THREE.Mesh(this.geometry, this.material)

		this.object.add(this.mesh)

		this.visible = true
	}

	setSize(w, h, r) {
		super.setSize(w, h, r)
		this.mesh.scale.x = 2 * r
		this.mesh.scale.y = 1 * this.resolution
	}

	set visible(val) {
		// タッチデバイスでは表示しない
		if (Polaris.device.hasTouch) {
			this.object.visible = false
		} else {
			this.object.visible = val
		}
	}

	get y() {
		return this._y
	}

	set y(val) {
		this._y = val
		this.object.position.y = this.canvasH / 2 - val * this.menu.resolution
	}
}