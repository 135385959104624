
import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'

import pageReducer from './page/reducers'

import menuReducer from './menu/reducers'

import scrollReducer from './scroll/reducers'

import deviceReducer from './device/reducers'

import loaderReducer from './loader/reducers'

import noticeReducer from './notice/reducers'

import imagesReducer from './images/reducers'


const createRootReducer = (history) => combineReducers({
	router : connectRouter(history),
	page   : pageReducer,
	menu   : menuReducer,
	scroll : scrollReducer,
	device : deviceReducer,
	loader : loaderReducer,
	notice : noticeReducer,
	images : imagesReducer,
})


export default createRootReducer