
import React from 'react'

import $ from 'jquery'

import { Section, Container, Inner, Title } from 'views/components/common/section/index'

import styles from './index.module.scss'


class Concept extends React.Component {

	accordion = React.createRef()

	state = {
		expanded : false
	}


	toggle() {
		const expanded = !this.state.expanded

		this.setState({
			expanded : expanded
		})

		const $a = $(this.accordion.current)
		const h0 = $a.height()
		const h1 = expanded ? $a.css({height:'auto'}).height() : 0

		$a.stop().css({height:h0}).animate({height:h1}, 750, 'ioX4', () => {
			if (this.state.expanded) $a.css({height:'auto'})
		})
	}

	render() {
		return (
			<Section className={styles.concept}>

				<button className={styles.button} onClick={this.toggle.bind(this)} aria-expanded={this.state.expanded}></button>

				<div ref={this.accordion} className={styles.accordion}>
					<Container>
						<Title
							srcSP="/assets/img/subtitle-concept@sp.png"
							srcPC="/assets/img/subtitle-concept@pc.png"
							alt="CONCEPT" />
						<Inner>
							<div className={styles.text} dangerouslySetInnerHTML={{__html:this.props.payload.concept}} />
						</Inner>
					</Container>
				</div>
			</Section>
		)
	}
}

export default Concept