
import React from 'react'

import styles from './inner.module.scss'


const Inner = (props) => (
	<div className={[styles.inner, props.className].join(' ')}>
		{props.children}
	</div>
)

export default Inner