
import React from 'react'

import { NavLink } from 'react-router-dom'

import Scrollin from 'helpers/scrollin'

import Image from './image'

import styles from './link.module.scss'


const Link = (props) => (
	<Scrollin rate={0.9} render={(hidden) => (
		<NavLink exact to={props.path} className={styles.link} aria-hidden={hidden}>
			<Image image={props.image} hidden={hidden} />
		</NavLink>
	)} />
)

export default Link
