
import React from 'react'

import { connect } from 'react-redux'

import { Power2 } from 'gsap'

import Glaze3D from 'lib/glaze/index'

import styles from './index.module.scss'


class MainImage extends React.Component {

	wrapper = React.createRef()

	state = {
		animated : false
	}


	get image() {
		if (this.props.menu[this.props.pIndex] && this.props.menu[this.props.pIndex].children) {
			if (this.props.pIndex !== -1 && this.props.cIndex !== -1) {
				if (this.props.device === 'sp') {
					return this.props.menu[this.props.pIndex].children[this.props.cIndex].main_image_sp
				} else {
					return this.props.menu[this.props.pIndex].children[this.props.cIndex].main_image_pc
				}
			} else {
				if (this.props.device === 'sp') {
					return this.props.payload.main_image_sp
				} else {
					return this.props.payload.main_image_pc
				}
			}
		} else {
			if (this.props.device === 'sp') {
				return this.props.payload.main_image_sp
			} else {
				return this.props.payload.main_image_pc
			}
		}
	}

	componentDidMount() {
		this.glaze = new Glaze3D(this.wrapper.current)
		
		this.glaze.loadByImg(this.image.img).then(() => {

			this.glaze.start()

			this.glaze.delay(0.3).animate(2.0, Power2.easeOut).then(() => {
				this.setState({animated:true})

				setTimeout(() => {
					this.dispose()
				})
			})
		})
	}

	componentWillUnmount() {
		this.dispose()
	}

	dispose() {
		if (this.glaze) {
			this.glaze.destroy()
			this.glaze = null
		}
	}

	render() {
		return (
			<div className={styles.mainimage}>
				{this.image && (
					<img src={this.image.src} alt={this.image.alt} style={{opacity:this.state.animated ? 1:0}} />
				)}

				{!this.state.animated && (
					<div ref={this.wrapper} className={styles.wrapper}></div>
				)}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		menu   : state.loader.payload.menu,
		pIndex : state.menu.pIndex,
		cIndex : state.menu.cIndex,
	}
}

export default connect(mapStateToProps)(MainImage)
