
import {
	NOTICE_READY,
	NOTICE_CLOSE,
} from './types'


export const ready = function(data) {
	return {type:NOTICE_READY, notices:data}
}

export const close = function(index) {
	return {type:NOTICE_CLOSE, index:index}
}

export default {
	ready,
	close,
}