
import React from 'react'

import { connect } from 'react-redux'

import { Power2 } from 'gsap'

import Glaze3D from 'lib/glaze/index'

import styles from './image.module.scss'


class Image extends React.Component {

	wrapper = React.createRef()

	state = {
		animated : false
	}


	componentDidMount() {
		// PCのみ
		if (this.props.device !== 'sp' || true) {
			this.glaze = new Glaze3D(this.wrapper.current)

			this.loader = new Promise((resolve) => {
				this.glaze.loadBySrc(this.props.image.src).then(() => {
					resolve()
				}).catch(() => {
					this.showImage()
				})
			})
		} else {
			this.setState({animated:true})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.hidden !== prevProps.hidden && !this.props.hidden) {
			this.show()
		}
	}

	componentWillUnmount() {
		this.dispose()
	}

	show() {
		if (this.glaze) {
			this.loader.then(() => {
				
				this.glaze.start()

				this.glaze.delay(0.5).animate(2.0, Power2.easeOut).then(() => {
					this.showImage()
				})
			})
		}
	}

	showImage() {
		this.setState({animated:true})
		setTimeout(this.dispose.bind(this))
	}

	dispose() {
		if (this.glaze) {
			this.glaze.destroy()
			this.glaze = null
		}
	}

	render() {
		return (
			<div className={styles.image}>
				<img src={this.props.image.src} alt={this.props.image.alt} style={{opacity:this.state.animated ? 1:0}} />

				{!this.state.animated && (
					<div ref={this.wrapper} className={styles.wrapper} />
				)}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		finish : state.menu.finish,
		device : state.device.mode
	}
}

export default connect(mapStateToProps)(Image)