
import React from 'react'

import { Container, Title, Inner } from 'views/components/common/section/index'

import Link from './link'

import styles from './index.module.scss'


const Neighbor = (props) => (
	<div className={styles.neighbor}>
		<Container>
			<Title
				srcSP={`/assets/img/subtitle-other_${props.type}@sp.png`}
				srcPC={`/assets/img/subtitle-other_${props.type}@pc.png`}
				alt={`OTHER ${props.type.toUpperCase()}`} />

			<Inner>
				<ul>
					<li><Link device={props.device} {...props.payload.neighbors.next} /></li>
					<li><Link device={props.device} {...props.payload.neighbors.prev} /></li>
				</ul>
			</Inner>
		</Container>
	</div>
)

export default Neighbor
