
import React from 'react'

import withLoading from 'helpers/withLoading'
import MenuReady   from 'helpers/menuready'

import ScrollTop   from 'views/components/snippet/scrolltop/index'
import MainImage   from 'views/components/snippet/mainimage/index'
import MainVideo   from 'views/components/snippet/mainvideo/index'
import Neighbor    from 'views/components/snippet/neighbor/index'
import MoreLink    from 'views/components/snippet/morelink/index'
import Side        from 'views/components/snippet/side/index'
import Title       from 'views/components/snippet/title/index'
import Concept     from 'views/components/snippet/concept/index'
import Information from 'views/components/snippet/information/index'
import Images      from 'views/components/snippet/images/index'
import Movie       from 'views/components/snippet/movie/index'
import Items       from 'views/components/snippet/items/index'
import Designers   from 'views/components/snippet/designers/index'

import styles from './index.module.scss'


const Installation = (props) => (
	<div className={styles.installation}>
		<MenuReady render={() => (
			<React.Fragment>
				<Title {...props} />

				{props.payload.main_type === 'image' && (
					<MainImage {...props} />
				)}

				{props.payload.main_type === 'video' && (
					<MainVideo {...props} />
				)}

				{/* CONCEPT */}
				{props.payload.concept && (
					<Concept {...props} />
				)}

				{/* インフォメーション */}
				{props.payload.information && (
					<Information {...props} />
				)}

				{/* Images */}
				{props.payload.exhibition && (
					<Images hasTitle={false} {...props} />
				)}

				{/* MOVIE */}
				{props.payload.movies && (
					<Movie {...props} />
				)}

				{/* ITEMS */}
				{props.payload.item_groups && (
					<Items {...props} />
				)}

				{/* DESIGNERS */}
				{props.payload.designers && (
					<Designers {...props} />
				)}

				<ScrollTop />

				{props.payload.neighbors && (
					<Neighbor type={"installation"} {...props} />
				)}

				<MoreLink {...props} />

				<Side link="/installation/" />
				
			</React.Fragment>
		)} />
	</div>
)

export default withLoading(Installation)