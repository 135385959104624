
import React from 'react'

import { connect } from 'react-redux'


class MenuLoaded extends React.Component {

	state = {
		loaded : false
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.menudata !== prevProps.menudata && this.props.menudata) {
			this.setState({loaded:true})
		}
	}

	render(child) {
		if (this.state.loaded) {
			return this.props.children
		} else {
			return null
		}
	}
}

function mapStateToProps(state) {
	return {
		menudata : state.loader.payload
	}
}

export default connect(mapStateToProps)(MenuLoaded)