
import {
	NOTICE_READY,
	NOTICE_CLOSE,
} from './types'


const initialState = {
	index   : -1,
	notices : []
}


export default function noticeReducer(state = initialState, action) {

	switch (action.type) {
		case NOTICE_READY:
			return {...state, notices:action.notices}

		case NOTICE_CLOSE:
			state.notices[action.index].closed = true
			return {...state, notices:state.notices, index:action.index}

		default:
			return state

	}
}