
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './index.module.scss'


const Footer = () => (
	<footer className={styles.footer}>
		<div>
			<div className={styles.logo}>
				<NavLink exact to="/"><img src="/assets/img/logo@pc.png" alt="" /></NavLink>
			</div>
			<div className={styles.copyright}>
				<span><span role="img" aria-label="(c)">©️</span> 有限会社マルヒロ / 2019</span>
			</div>
		</div>
	</footer>
)

export default Footer