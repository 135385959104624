
import React from 'react'

import styles from './link.module.scss'


const Link = (props) => {
	if (props.type === '0') {
		return (
			<a href={props.href} className={styles.link} target="_blank" rel="noopener noreferrer">
				<img src={props.image.src} alt={props.image.alt} />
			</a>
		)
	} else {
		return (
			<div className={styles.area}>
				<img src={props.image.src} alt={props.image.alt} />

				{props.type === '1' && (
					<span><img src="/assets/img/item-coming_soon.svg" alt="COMING SOON" /></span>
				)}
				{props.type === '2' && (
					<span><img src="/assets/img/item-sold_out.svg" alt="SOLD OUT" /></span>
				)}
				{props.type === '3' && (
					<span><img src="/assets/img/item-not_for_sale.svg" alt="NOT FOR SALE" /></span>
				)}
			</div>
		)
	}
}

export default Link