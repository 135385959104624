
import {
	IMAGES_MODAL_OPEN,
	IMAGES_MODAL_CLOSE
} from './types'


const initialState = {
	open  : false,
	index : -1,
}


export default function exhibitionReducer(state = initialState, action) {

	switch (action.type) {
		case IMAGES_MODAL_OPEN:
			return {...state, open:true, index:action.index}

		case IMAGES_MODAL_CLOSE:
			return {...state, open:false}

		default:
			return state

	}
}