
import React from 'react'

import { CSSTransition } from 'react-transition-group'

import { connect } from 'react-redux'

import actions from 'states/images/actions'

import Slide from './slide'

import styles from './index.module.scss'


const animation = {
	enterActive  : styles.enterActive,
	enterDone    : styles.enterDone,
	exitActive   : styles.exitActive,
}

class Modal extends React.Component {

	close() {
		this.props.closeModal()
	}

	render() {
		return (
			<CSSTransition in={this.props.openModal} timeout={500} mountOnEnter={true} unmountOnExit={true} classNames={animation}>
				<div className={styles.modal}>
					<Slide start={this.props.startIndex} items={this.props.items} />

					<button onClick={this.close.bind(this)} className={styles.close}>
						<img src="/assets/img/button-cross.svg" alt="CLOSE" />
					</button>
				</div>
			</CSSTransition>
		)
	}
}

function mapStateToProps(state) {
	return {
		openModal  : state.images.open,
		startIndex : state.images.index
	}
}

function mapDispatchToProps(dispatch) {
	return {
		closeModal : () => dispatch(actions.closeModal())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)