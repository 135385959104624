
import React from 'react'

import withLoading from 'helpers/withLoading'

import styles from './index.module.scss'


const Home = (props) => {
	return <div className={styles.home}></div>
}

export default withLoading(Home)