
import React from 'react'

import withLoading from 'helpers/withLoading'

import MoreLink  from 'views/components/snippet/morelink/index'
import Side      from 'views/components/snippet/side/index'

import styles from './index.module.scss'


const Maruhiro = (props) => {
	return (
		<div className={styles.comingsoon}>

			<div className={styles.title}>
				<h1>
					<div>
						{props.device === 'sp' ? (
							<img src="/assets/img/title-comingsoon@sp.png" alt="COMING SOON" />
						) : (
							<img src="/assets/img/title-comingsoon@pc.png" alt="COMING SOON" />
						)}
					</div>
				</h1>
			</div>

			<MoreLink {...props} />

			<Side link="/" />
			
		</div>
	)
}

export default withLoading(Maruhiro)