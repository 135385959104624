
import React from 'react'

import { connect } from 'react-redux'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import Scrollin from 'helpers/scrollin'

import Video from './video'

import styles from './slide.module.scss'


class Slide extends React.Component {

	slide = React.createRef()
	mover = React.createRef()

	state = {
		current    : 0,
		prevActive : true,
		nextActive : true
	}

	set moverX(x) {
		this._x = x;

		if (this.mover.current) {
			this.mover.current.style.transform = `translate3d(${this._x}px, 0, 0)`
		}
	}

	get moverX() {
		return this._x || 0;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.finish !== prevProps.finish && this.props.finish) {
			this.ready()
		}
	}

	ready() {
		let tx, ty, dx, dy, flick, start

		this.slideNum = this.mover.current.getElementsByTagName('li').length

		this.resizeID = Polaris.util.onResize(() => {
			this.resize()
		})

		$(this.slide.current).on({

			'mousedown touchstart' : (e) => {
				Polaris.util.offFrame(this.frameID);
				tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
				ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY
				dx = 0
				dy = 0
				flick = null
				start = this.moverX
			},

			'mousemove touchmove' : (e) => {
				if (start !== null && flick !== false) {
					dx = (e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX) - tx
					dy = (e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY) - ty

					if (flick === null) {
						flick = Math.abs(dx) >= Math.abs(dy)

						if (flick) {
							e.preventDefault()
						} else {
							this.change(this.state.current)
							start = null
						}
					} else {
						if (flick) {
							e.preventDefault()
							this.moverX = start + dx
						}
					}
				}
			},

			'mouseleave mouseup touchend touchcancel' : (e) => {
				if (start !== null) {
					if (Math.abs(dx) > 50) {
						if (this.state.current === 0) {
							if (dx > 0) {
								this.change(this.state.current)
							} else {
								this.next()
							}
						} else if (this.state.current === this.slideNum - 1) {
							if (dx > 0) {
								this.prev()
							} else {
								this.change(this.state.current)
							}
						} else {
							if (dx > 0) {
								this.prev()
							} else {
								this.next()
							}
						}
					} else {
						this.change(this.state.current);
					}
					start = null;
				}
			}
		})
	}

	componentWillUnmount() {
		Polaris.util.offFrame(this.frameID)
		Polaris.util.offResize(this.resizeID)
		$(this.mover.current).off('*')
	}

	prev(e) {
		this.change(this.state.current - 1);
	}

	next (e) {
		this.change(this.state.current + 1);
	}

	resize() {
		this.change(this.state.current, true);
	}

	change(index, atOnce=false) {
		Polaris.util.offFrame(this.frameID);

		index = Polaris.util.clamp(index, 0, this.slideNum-1)

		if (atOnce) {
			this.animate(index, 0)
		} else {
			this.animate(index, 700)
		}
	}

	animate(index, duration) {
		const x0 = this.moverX;
		const x1 = -1 * this.mover.current.clientWidth * index

		this.setState({
			current    : index,
			prevActive : index > 0,
			nextActive : index < this.slideNum -1
		})

		this.frameID = Polaris.util.onFrame((ct, dt, pt) => {

			const p = duration > 0 ? Polaris.util.clamp(pt / duration, 0, 1) : 1

			this.moverX = x0 + (x1 - x0) * Polaris.easing.ioX4(p)

			if (p === 1) return false
		});
	}

	render() {
		return (
			<Scrollin rate={0.7} render={(hidden) => (
				<div ref={this.slide} className={styles.slide} aria-hidden={hidden}>
					<div className={styles.wrap}>
						<div className={styles.mover} ref={this.mover}>
							<ul style={{width:this.props.items.length*100+'%'}}>
								{this.props.items.map((item, j) => (
								<li key={j} className={styles.item}>
									<div>
										<Video vid={item.video_id} current={this.state.current} />
									</div>
								</li>
								))}
							</ul>
						</div>
						<button className={styles.prev} onClick={this.prev.bind(this)} aria-hidden={!this.state.prevActive}>
							<img src="/assets/img/arrow-l.svg" alt="PREV" />
						</button>
						<button className={styles.next} onClick={this.next.bind(this)} aria-hidden={!this.state.nextActive}>
							<img src="/assets/img/arrow-r.svg" alt="NEXT" />
						</button>
					</div>
				</div>
			)} />
		)
	}
}

function mapStateToProps(state) {
	return {
		finish : state.menu.finish
	}
}

export default connect(mapStateToProps)(Slide)