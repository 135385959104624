
import React from 'react'

import Player from '@vimeo/player'

import styles from './video.module.scss'


class Video extends React.Component {

	wrapper = React.createRef()


	componentDidMount() {
		this.player = new Player(this.wrapper.current, {
			id       : this.props.vid,
			controls : true,
			autoplay : false,
			playsinline : true
		})
	}

	componentWillUnmount() {
		this.player.destroy()
	}

	componentDidUpdate(prevProps) {
		if (this.props.current !== prevProps.current) {
			this.player.pause()
		}
	}

	render() {
		return (
			<div ref={this.wrapper} className={styles.video}></div>
		)
	}
}

export default Video
