
import React from 'react'

import styles from './container.module.scss'


const Container = (props) => (
	<div className={[styles.container, props.className].join(' ')}>
		{props.children}
	</div>
)

export default Container