
import React from 'react'

import Loaded   from 'helpers/menuloaded'
import Scroll   from 'helpers/scroll'

import Html      from 'views/containers/html'
import Wrapper   from 'views/containers/wrapper'
import Content   from 'views/containers/content'
import Scrollbar from 'views/containers/scrollbar'

import Gtag     from 'views/components/head/gtag/index'
import Meta     from 'views/components/head/meta/index'
import Menu     from 'views/components/common/menu/index'
import Notices  from 'views/components/common/notices/index'
import Footer   from 'views/components/common/footer/index'


const App = (props) => (
	<Html>
		<Gtag />
		<Meta />
		<Scroll />
		<Scrollbar />

		<Wrapper>
			<Menu />
			<Notices />
			<Loaded>
				<Content />
			</Loaded>
			<Footer />
		</Wrapper>
	</Html>
)

export default App
