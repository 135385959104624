
import React from 'react'

import { Section, Container } from 'views/components/common/section/index'

import styles from './index.module.scss'


const Designers = (props) => (
	<Section className={styles.designers}>
		<ul>
		{props.payload.designers.map((designer, i) => {
			return (
				<li key={i}>
					<Container>
						<div className={styles.left}>
							{props.device === 'pc' ? (
								<div className={styles.image} style={{backgroundImage:`url(${designer.image_pc.src})`}}></div>
							) : (
								<img src={designer.image_sp.src} alt={designer.image_sp.alt} />
							)}
						</div>
						<div className={styles.right}>
							<div>
								<h2 className={styles.title}>
									<img src={designer.title_image.src} alt={designer.title_image.alt} />
								</h2>
								<div className={styles.text}>
									<div dangerouslySetInnerHTML={{__html:designer.text}}></div>
								</div>
							</div>
						</div>
					</Container>
				</li>
			)
		})}
		</ul>
	</Section>
)

export default Designers