
import * as THREE from 'three'

import { TimelineMax, TweenMax } from 'gsap'

import Polaris from 'lib/Polaris'

import glaze_vert from './shaders/glaze_vert.glsl'
import glaze_frag from './shaders/glaze_frag.glsl'


export default class Glaze {

	constructor(wrapper) {
		this.wrapper  = wrapper
		this.canvasW  = 0
		this.canvasH  = 0
		this.scene    = new THREE.Scene()
		this.camera   = new THREE.OrthographicCamera(0, 0, 0, 0, -1000, 1000)
		this.renderer = new THREE.WebGLRenderer({alpha:true, antialias:true})
		this.timeline = new TimelineMax()

		this.wrapper.appendChild(this.renderer.domElement)
	}

	get resolution() {
		return Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)
	}


	loadBySrc(src) {
		return new Promise((resolve, reject) => {

			const img = new Image()

			img.onload = () => {
				if (this.renderer) {
					this.loadByImg(img)
					resolve()
				} else {
					reject()
				}
			}

			img.onerror = (err) => {
				reject(err)
			}

			img.crossOrigin = true

			setTimeout(() => {
				img.src = src
			})
			
		})
	}


	loadByImg(img) {
		return new Promise((resolve, reject) => {

			this.texture = new THREE.Texture(img)

			this.texture.magFilter = THREE.NearestFilter
			this.texture.minFilter = THREE.LinearFilter
			this.texture.generateMipmaps = false
			this.texture.needsUpdate = true

			this.geometry = new THREE.PlaneGeometry()

			this.material = new THREE.ShaderMaterial({
				uniforms : {
					texture : { value : this.texture },
					seed    : { value : Math.random() },
					time    : { value : 0.0 },
					opacity : { value : 0.0 }
				},
				vertexShader   : glaze_vert,
				fragmentShader : glaze_frag,
			})

			this.mesh = new THREE.Mesh(this.geometry, this.material)

			this.scene.add(this.mesh)

			resolve()
		})
	}


	start() {

		this.resizeID = Polaris.util.onResize(() => {
			this.canvasW = this.resolution * this.wrapper.clientWidth
			this.canvasH = this.resolution * this.wrapper.clientHeight

			this.camera.left   = this.canvasW / -2
			this.camera.right  = this.canvasW / +2
			this.camera.top    = this.canvasH / +2
			this.camera.bottom = this.canvasH / -2
			this.camera.updateProjectionMatrix()

			this.renderer.setSize(this.canvasW, this.canvasH)

			this.renderer.domElement.style.width  = '100%'
			this.renderer.domElement.style.height = '100%'
			
			this.mesh.scale.x = Math.max(1, this.canvasW)
			this.mesh.scale.y = Math.max(1, this.canvasH)
		})

		this.frameID = Polaris.util.onFrame(() => {
			if (this.mesh) {
				this.renderer.render(this.scene, this.camera)
			}
		})
	}


	destroy() {
		Polaris.util.offResize(this.resizeID)
		Polaris.util.offFrame(this.frameID)

		this.wrapper.removeChild(this.renderer.domElement)

		this.timeline.kill()
		this.timeline.clear()

		this.scene.dispose()
		this.renderer.dispose()

		if (this.mesh) {
			this.geometry.dispose()
			this.material.dispose()
		}
		if (this.texture) {
			this.texture.dispose()
		}

		this.wrapper  = null
		this.scene    = null
		this.camera   = null
		this.renderer = null
		this.timeline = null
		this.geometry = null
		this.material = null
		this.texture  = null
		this.mesh     = null
	}

	delay(delay) {
		this.timeline.add(TweenMax.to({v:0}, delay, {v:1}))
		return this
	}

	animate(duration, ease) {
		return new Promise((resolve) => {

			const queue = []

			queue.push(TweenMax.to(this.material.uniforms.opacity, duration, {
				ease  : ease,
				value : 1
			}))

			queue.push(TweenMax.to(this.material.uniforms.time, duration, {
				ease  : ease,
				value : 1,
			}))

			this.timeline.add(queue).call(resolve)
		})
	}
}