
import {
	LOADER_START,
	LOADER_PROGRESS,
	LOADER_SUCCESS,
	LOADER_FAILED
} from './types'


const initialState = {
	status   : null,
	progress : 0,
	payload  : null,
	error    : null,
}


export default function pageReducer(state = initialState, action) {

	switch (action.type) {
		case LOADER_START:
			return {...state, status:'loading'}

		case LOADER_PROGRESS:
			return {...state, status:'loading', progress:action.progress}

		case LOADER_SUCCESS:
			return {...state, status:'finished', payload:action.payload}

		case LOADER_FAILED:
			return {...state, status:'finished', error:action.error}

		default:
			return state

	}
}