
import React from 'react'

import Polaris from 'lib/Polaris'

import { connect } from 'react-redux'


window.history.scrollRestoration = 'manual'


class Scroll extends React.Component {

	componentDidMount() {
		this.scrollTop = 0
		this.scrollMap = new Map()

		Polaris.util.onScroll((t, b) => {
			this.scrollTop = t
		})
	}

	shouldComponentUpdate(nextProps) {

		// パス変化時に前のパスをキーにスクロール量を保存
		if (nextProps.router.location.pathname !== this.props.router.location.pathname) {
			this.scrollMap.set(this.props.router.location.pathname, this.scrollTop)
		}

		// レンダリング完了後、スクロール量を設定
		if (nextProps.ready !== this.props.ready && nextProps.ready) {

			if (this.props.router.action === 'PUSH') {
				window.scrollTo(0, 0)
			}

			if (this.props.router.action === 'POP') {
				window.scrollTo(0, this.scrollMap.get(window.location.pathname))
			}
		}

		return true
	}

	render() {
		return null
	}
}


function mapStateToProps(state) {
	return {
		router : state.router,
		ready  : state.menu.ready
	}
}

export default connect(mapStateToProps)(Scroll)
