
import * as THREE from 'three'

import { TimelineMax } from 'gsap'

import notice_vert from './shaders/notice_vert.glsl'
import notice_frag from './shaders/notice_frag.glsl'


export default class Notice {

	constructor(menu, props) {
		this.menu = menu
		this.props = props

		this.object = new THREE.Object3D()

		this.image = this.createImage()
		this.hitArea = this.createHitArea()
		this.close = this.createClose()

		this.object.add(this.image)
		this.object.add(this.hitArea)
		this.object.add(this.close)
		this.menu.layer2.add(this.object)

		this.timeline = new TimelineMax()
	}

	get zIndex() {
		return this.object.position.z
	}

	set zIndex(val) {
		this.object.position.z    = val
		this.image.position.z     = val - 3
		this.hitArea.position.z   = val - 2
		this.close.position.z = val - 1
	}

	createImage() {
		const geometry = new THREE.PlaneGeometry()

		const material = new THREE.ShaderMaterial({
			uniforms : {
				image_texture : { value : this.props.notice_image_pc.texture },
				close_texture : { value : new THREE.Texture() },
				image_pos  : { value : new THREE.Vector2(0, 0) },
				image_size : { value : new THREE.Vector2(0, 0) },
				close_pos  : { value : new THREE.Vector2(0, 0) },
				close_size : { value : new THREE.Vector2(0, 0) },
				opacity    : { value : 0.0 },
				seed       : { value : 0.0 },
				time       : { value : 0.0 },
			},
			vertexShader   : notice_vert(),
			fragmentShader : notice_frag(),
			transparent    : true
		})

		const mesh = new THREE.Mesh(geometry, material)

		new THREE.TextureLoader().load('/assets/img/button-cross.png', (texture) => {
			material.uniforms.close_texture.value = texture
		})

		return mesh
	}

	createHitArea() {
		const geometry = new THREE.PlaneGeometry()

		const material = new THREE.MeshBasicMaterial({transparent:true, opacity:0})

		const mesh = new THREE.Mesh(geometry, material)

		return mesh
	}

	createClose() {
		const geometry = new THREE.PlaneGeometry()

		const material = new THREE.MeshBasicMaterial({transparent:true, opacity:0})

		const mesh = new THREE.Mesh(geometry, material)

		return mesh
	}

	setSize(cw, ch, cr, bw) {
		this.image.scale.x = cw
		this.image.scale.y = ch

		this.hitArea.scale.x = (this.props.w * 0.5) / 1400 * bw
		this.hitArea.scale.y = (this.props.h * 0.5) / 1400 * bw

		this.hitArea.position.x = - cw / 2 + this.hitArea.scale.x / 2 + this.props.x * bw
		this.hitArea.position.y = + ch / 2 - this.hitArea.scale.y / 2 - this.props.y * bw

		this.close.position.x = - cw / 2 - this.close.scale.x / 2 + this.props.x * bw + this.hitArea.scale.x
		this.close.position.y = + ch / 2 - this.close.scale.y / 2 - this.props.y * bw

		this.image.material.uniforms.image_size.value.x = this.hitArea.scale.x / cw
		this.image.material.uniforms.image_size.value.y = this.hitArea.scale.y / ch

		this.image.material.uniforms.image_pos.value.x = this.props.x * (bw / cw)
		this.image.material.uniforms.image_pos.value.y = this.props.y * (bw / ch)

		if (this.menu.isSP) {
			this.close.scale.x = (32 + 20 * 2) / 750 * bw
			this.close.scale.y = (32 + 20 * 2) / 750 * bw

			this.image.material.uniforms.close_size.value.x = (32 + 20 * 2) / 750 * bw / cw
			this.image.material.uniforms.close_size.value.y = (32 + 20 * 2) / 750 * bw / ch

			this.image.material.uniforms.close_pos.value.x = this.props.x * (bw / cw) + this.hitArea.scale.x / cw - (32 + 20 * 2) / 750 * bw / cw
			this.image.material.uniforms.close_pos.value.y = this.props.y * (bw / ch)
		} else {
			this.close.scale.x = (32 + 20 * 2) / 1400 * bw
			this.close.scale.y = (32 + 20 * 2) / 1400 * bw

			this.image.material.uniforms.close_size.value.x = (32 + 20 * 2) / 1400 * bw / cw
			this.image.material.uniforms.close_size.value.y = (32 + 20 * 2) / 1400 * bw / ch

			this.image.material.uniforms.close_pos.value.x = this.props.x * (bw / cw) + this.hitArea.scale.x / cw - (32 + 20 * 2) / 1400 * bw / cw
			this.image.material.uniforms.close_pos.value.y = this.props.y * (bw / ch)
		}
	}


	delay(delay) {
		this.timeline.kill()
		this.timeline.clear()
		this.timeline.delay(delay)
		return this
	}

	show(duration, ease) {
		const queue = []

		this.image.material.uniforms.time.value = 1.0
		this.image.material.uniforms.seed.value = Math.random()

		queue.push(this.menu.tween(this.image.material.uniforms.opacity, duration, {value:1, ease:ease}))
		queue.push(this.menu.tween(this.image.material.uniforms.time,    duration, {value:0, ease:ease}))

		this.timeline.add(queue)
	}


	hide(duration, ease) {
		this.timeline.kill()
		this.timeline.clear()

		const queue = []

		this.image.material.uniforms.time.value = 0.0
		this.image.material.uniforms.seed.value = Math.random()

		queue.push(this.menu.tween(this.image.material.uniforms.opacity, duration, {value:0, ease:ease}))
		queue.push(this.menu.tween(this.image.material.uniforms.time,    duration, {value:1, ease:ease}))

		this.timeline.add(queue).call(() => {
			this.object.visible = false
		})

		this.close.visible = false
		this.hitArea.visible = false
	}
}