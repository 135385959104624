
import React from 'react'

import { connect } from 'react-redux'

import styles from './title.module.scss'


const Title = (props) => (
	<h2 className={[styles.title, props.className].join(' ')}>
		{props.device === 'sp' ? (
			<img src={props.srcSP} alt={props.alt} />
		) : (
			<img src={props.srcPC} alt={props.alt} />
		)}
	</h2>
)

function mapStateToProps(state) {
	return {
		device : state.device.mode,
	}
}

export default connect(mapStateToProps)(Title)