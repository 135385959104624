
export const MENU_CHANGE = "MENU_CHANGE"

export const MENU_LOADED = "MENU_LOADED"

export const MENU_READY = "MENU_READY"

export const MENU_FINISH = "MENU_FINISH"

export const MENU_CLOSE = "MENU_CLOSE"

export const MENU_CHANGE_PREV_BACK = "MENU_CHANGE_PREV_BACK"

export const MENU_CHANGE_NEXT_BACK = "MENU_CHANGE_NEXT_BACK"

export const MENU_RESET_PREV_BACK = "MENU_RESET_PREV_BACK"

export const MENU_RESET_NEXT_BACK = "MENU_RESET_NEXT_BACK"
