
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/images/actions'

import Scrollin from 'helpers/scrollin'

import styles from './item.module.scss'


const Item = (props) => (
	<li className={styles.item} data-size={props.size} style={props.style} onClick={() => props.openModal(props.index)}>
		<Scrollin rate={0.5} render={(hidden) => (
			<div className={styles.wrap} aria-hidden={hidden}>
				<img src={props.image.src} alt={props.image.alt} />
			</div>
		)} />
	</li>
)

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
		openModal : (index) => dispatch(actions.openModal(index))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Item)