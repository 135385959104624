
import React from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import styles from './content.module.scss'

import Home from 'views/components/home/index'

import ComingSoon from 'views/components/comingsoon/index'

// import Maruhiro from 'views/components/maruhiro/index'

// import Mood from 'views/components/mood/index'

// import About from 'views/components/about/index'

import Collection from 'views/components/collection/index'

import Installation from 'views/components/installation/index'

import Archive from 'views/components/archive/index'

import Null from 'views/components/null/index'


const timeout = {
	appear : 0,
	enter  : 700,
	exit   : 700
}

const Content = ({ location }) => (
	<TransitionGroup component={null} appear={false} enter={true} exit={true}>
		<CSSTransition key={location.key} timeout={timeout} classNames={{...styles}}>
			<Switch location={location}>

				{/*  */}
				<Route exact component={Home} path="/" />

				<Route exact component={ComingSoon} path="/maruhiro/" />

				<Route exact component={Null} path="/collection/" />

				<Route exact component={Collection} path="/collection/:slug" />

				<Route exact component={Null} path="/installation/" />

				<Route exact component={Installation} path="/installation/:slug" />

				<Route exact component={Null} path="/archives/" />

				<Route exact component={Archive} path="/archives/:slug" />

				<Route exact component={ComingSoon} path="/mood-inspiration/" />

				<Route exact component={ComingSoon} path="/about-killtime/" />

				<Route component={Null} />
				
			</Switch>
		</CSSTransition>
	</TransitionGroup>
)

export default withRouter(Content)
