
import $ from 'jquery'

import Polaris from 'lib/Polaris'


export default class Exhibition {

	constructor(wrapper, items) {

		this.li = $(wrapper).find('li')

		let zIndex = 0

		this.items = items.map((item, i) => {

			const $el = this.li.eq(i)

			$el.mouseenter(() => {
				$el.css({zIndex:++zIndex})
			})

			return {
				size : parseInt(item.size),
				el   : $el,
				dy   : 0,
				ty   : null,
				dz   : Polaris.util.rand(15, 30) * 0.01
			}
		})

		// 配置決め
		this.items.reduce((carry, item) => {
			let minX, maxX, minY, maxY

			// 横方向
			if (item.size === 4) {
				minX = -15
				maxX = +15
			} else if (item.size === 3) {
				minX = -3
				maxX = +3
			} else {
				if (carry.left) {
					// 左配置
					if (item.size === 1) {
						minX = -13
						maxX = -8
					}
					if (item.size === 2) {
						minX = -23
						maxX = -15
					}
				} else {
					// 右配置
					if (item.size === 1) {
						minX = 8
						maxX = 13
					}
					if (item.size === 2) {
						minX = 15
						maxX = 23
					}
				}
			}

			// 縦方向
			if (carry.size === -1) {
				minY = 0
				maxY = 1
			} else {
				if (item.size === 1) {
					if (carry.left) {
						minY = -20
						maxY = -10
					} else {
						minY = -15
						maxY = -10
					}
				} else if (item.size === 2) {
					if (carry.left) {
						minY = -20
						maxY = -10
					} else {
						minY = -45
						maxY = -35
					}
				} else if (item.size === 3) {
					minY = -10
					maxY = 5
				} else if (item.size === 4) {
					minY = -10
					maxY = 5
				}
			}

			item.marginL = Polaris.util.rand(minX, maxX)
			item.marginT = Polaris.util.rand(minY, maxY)

			if (item.size === 3 || item.size === 4) {
				return {size:item.size, left:true}
			} else {
				return {size:item.size, left:!carry.left}
			}
		}, {size:-1, left:true})

		this.items.forEach((item) => {
			item.el.css({
				marginLeft : item.marginL+'%',
				marginTop  : item.marginT+'%',
			})
		})
	}

	destroy() {
		Polaris.util.offScroll(this.scrollID)
		Polaris.util.offFrame(this.frameID)
	}


	start() {
		this.scrollID = Polaris.util.onScroll((t, b) => {
			this.items.forEach((item) => {
				item.dy = Polaris.util.clamp((item.el.offset().top - t) * item.dz, -100, 100)

				if (item.ty === null) {
					item.ty = item.dy
				}
			})
		})

		this.frameID = Polaris.util.onFrame((ct, dt, pt) => {
			this.items.forEach((item) => {
				item.ty += (item.dy - item.ty) * Math.min(dt, 50) * 0.002
				item.el.css({transform:`translate3d(-50%, ${item.ty}px, 0)`})
			})
		})
	}
}