
import React from 'react'

import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import Menu3D from 'lib/menu2/index'

import menuActions from 'states/menu/actions'

import noticeActions from 'states/notice/actions'

import styles from './content.module.scss'


class MenuContent extends React.Component {

	wrapper = React.createRef()

	state = {
		nIndex : -1,
	}

	componentDidMount() {
		this.menu3d = new Menu3D(this.wrapper.current)
	}

	componentDidUpdate(prevProps, prevState) {

		// PC・SP切り替え
		if (this.props.device !== prevProps.device) {
			this.menu3d.mode = this.props.device
		}

		// ローディング進捗
		if (this.props.progress !== prevProps.progress) {
			this.menu3d.progress(this.props.progress)
		}

		// メニュー用データのロード待機
		if (this.props.menudata !== prevProps.menudata && this.props.menudata) {
			this.menu3d.loadMenu(this.props.menudata)
			this.setEvent()
			this.startTimer()
		}

		if (this.props.pIndex !== prevProps.pIndex || this.props.cIndex !== prevProps.cIndex) {
			if (this.props.pIndex !== -1) {
				if (this.isExpanded()) {
					this.menu3d.select(this.props.pIndex, this.props.cIndex)
				} else {
					this.menu3d.openSubmenu(this.props.pIndex)
				}
			} else {
				this.menu3d.close()
			}
		}

		if (this.props.loaded !== prevProps.loaded && this.props.loaded) {
			if (this.props.pIndex !== -1) {
				if (this.isExpanded()) {
					this.menu3d.expand(this.props.pIndex, this.props.cIndex, this.props.ready, this.props.finish)
				}
			}
		}

		if (this.props.notices !== prevProps.notices) {
			this.menu3d.loadNotices(this.props.notices)
		}

		if (this.props.nIndex !== prevProps.nIndex) {
			this.menu3d.closeNotice(this.props.nIndex)
		}
	}

	setEvent() {

		$(this.wrapper.current).on('mousemove', (e) => {
			this.menu3d.mousemove(e.offsetX, e.offsetY)
		})

		$(this.wrapper.current).on('mouseleave', (e) => {
			this.menu3d.mousemove(-1)
		})

		$(this.wrapper.current).on('touchstart', (e) => {
			this.menu3d.mousemove(e.originalEvent.touches[0].clientX, e.originalEvent.touches[0].clientY)
		})

		$(this.wrapper.current).on('click', (e) => {
			this.menu3d.click(e.offsetX, e.offsetY)
		})

		Polaris.util.onScroll(() => {
			if (this.menu3d.props.rotation !== 0) {
				this.menu3d.wheel(true)
			}
		})

		// メニューにマウスオーバー
		let _pIndex = null
		let _cIndex = null

		this.menu3d.on('hover:menu', ({ pIndex, cIndex }) => {
			if (_pIndex !== pIndex || _cIndex !== cIndex) {
				if (cIndex !== -1 && this.menu3d.cIndex === -1) {
					this.menu3d.changeTexture(pIndex, cIndex)
				}
				if (cIndex === _cIndex && pIndex !== 0) {
					this.menu3d.distort()
				}
				if (pIndex !== 0) {
					this.menu3d.hover(pIndex, cIndex)
				}
			}

			_pIndex = pIndex
			_cIndex = cIndex


			this.setState({
				nIndex : -1
			})
		})

		// メニューをクリック
		this.menu3d.on('click:menu', ({ pIndex, cIndex }) => {

			if (pIndex === 0) return // とりあえずMARUHIROのリンクはクリック不可に

			// メニュークリック
			if (pIndex !== -1) {
				if (cIndex !== -1) {
					this.props.history.push(this.props.menudata.menu[pIndex].children[cIndex].path)
				} else {
					if (pIndex !== this.props.pIndex) {
						this.props.history.push(this.props.menudata.menu[pIndex].path)

						// 親メニュー遷移時は歪ませる
						if (!this.props.menudata.menu[pIndex].children) {
							this.menu3d.distort()
						}
					} else {
						this.props.history.push('/')
					}
				}

				this.menu3d.hover(pIndex, cIndex)
			}
		})


		// ロゴにマウスオーバー
		this.menu3d.on('hover:logo', () => {
			if (_pIndex !== -999) {
				_pIndex = -999
				this.menu3d.distort()
				this.menu3d.hoverLogo()
			}

			this.setState({
				nIndex : -1
			})
		})

		// ロゴをクリック
		this.menu3d.on('click:logo', () => {
			window.open('https://www.hasamiyaki.jp/')
		})


		// 告知画像にマウスオーバー
		this.menu3d.on('hover:notice', ({ nIndex }) => {
			this.setState({
				nIndex : nIndex
			})
		})

		// 告知画像をクリック
		this.menu3d.on('click:notice', ({ nIndex }) => {
			const link = this.props.notices[nIndex].link

			if (link.match(/^\//)) {
				this.props.history.push(link)
			} else {
				window.open(link)
			}
		})


		// 告知閉じるボタンにマウスオーバー
		this.menu3d.on('hover:close', ({ nIndex }) => {
			this.setState({
				nIndex : nIndex
			})
		})

		// 告知閉じるボタンをクリック
		this.menu3d.on('click:close', ({ nIndex }) => {
			this.props.close(nIndex)

			this.setState({
				nIndex : -1
			})
		})
	}

	startTimer() {
		this.timerID = setTimeout(() => {
			if (this.menu3d.props.rotation === 0) {
				this.menu3d.wheel()
			}
			this.startTimer()
		}, Polaris.util.rand(2000, 3000))
	}

	isExpanded() {
		if (this.props.pIndex !== -1) {
			if (this.props.cIndex !== -1) {
				return true
			} else {
				if (this.props.menudata.menu[this.props.pIndex].children) {
					return false
				} else {
					return true
				}
			}
		} else {
			return false
		}
	}

	render() {
		return (
			<div
				ref={this.wrapper}
				className={styles.menu}
				data-expanded={this.isExpanded()}
				data-show-cursor={this.state.nIndex !== -1}
			/>
		)
	}
}

function mapStateToProps(state) {
	return {
		device : state.device.mode,

		menudata : state.loader.payload,
		progress : state.loader.progress,

		pIndex : state.menu.pIndex,
		cIndex : state.menu.cIndex,
		loaded : state.menu.loaded,

		nIndex  : state.notice.index,
		notices : state.notice.notices,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		ready  : () => dispatch(menuActions.ready()),
		finish : () => dispatch(menuActions.finish()),
		close  : (i) => dispatch(noticeActions.close(i)),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuContent))