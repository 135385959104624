
export default `

vec4 blend(vec4 src, vec4 dst) {
	float a = src.w + dst.w * (1.0 - src.w);
	float r = (src.x * src.w + dst.x * dst.w * (1.0 - src.w) ) / a;
	float g = (src.y * src.w + dst.y * dst.w * (1.0 - src.w) ) / a;
	float b = (src.z * src.w + dst.z * dst.w * (1.0 - src.w) ) / a;
	return vec4(r, g, b, a);
}

vec4 overlay(vec4 src, vec4 dst) {
	float rc;
	float gc;
	float bc;

	src.x = 1.0 - src.x;
	src.y = 1.0 - src.y;
	src.z = 1.0 - src.z;

	if (dst.x < 0.5) {
		rc = src.x * dst.x * 2.0;
	} else {
		rc = 1.0 - 2.0 * (1.0 - src.x) * (1.0 - dst.x);
	}
	if (dst.y < 0.5) {
		gc = src.y * dst.y * 2.0;
	} else {
		gc = 1.0 - 2.0 * (1.0 - src.y) * (1.0 - dst.y);
	}
	if (dst.z < 0.5) {
		bc = src.z * dst.z * 2.0;
	} else {
		bc = 1.0 - 2.0 * (1.0 - src.z) * (1.0 - dst.z);
	}

	float r = src.w * rc + (1.0 - src.w) * dst.x;
	float g = src.w * gc + (1.0 - src.w) * dst.y;
	float b = src.w * bc + (1.0 - src.w) * dst.z;

	return vec4(r, g, b, 1.0);
}

vec4 invert(vec4 text, vec4 back) {
	if (text.w > 0.0) {
		float min = min(back.x, min(back.y, back.z));
		float max = max(back.x, max(back.y, back.z));
		float sum = min + max;

		float rc = 1.0 - back.x;
		float gc = 1.0 - back.y;
		float bc = 1.0 - back.z;

		text.w += pow(text.w, 3.0);

		float r = text.w * rc + (1.0 - text.w) * back.x;
		float g = text.w * gc + (1.0 - text.w) * back.y;
		float b = text.w * bc + (1.0 - text.w) * back.z;

		return vec4(r, g, b, 1.0);
	} else {
		return back;
	}
}

vec4 sharp(vec4 text) {
	if (text.w > 0.1) {
		text.w += pow(text.w, 3.0);

		float r = text.x * text.w + (1.0 - text.w);
		float g = text.y * text.w + (1.0 - text.w);
		float b = text.z * text.w + (1.0 - text.w);

		return vec4(r, g, b, 1.0);
	} else {
		return vec4(1.0);
	}
}

`