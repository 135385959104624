
import * as THREE from 'three'

import { TimelineMax } from 'gsap'

import MenuObject from './menuobject'


class Loading extends MenuObject {

	constructor(menu) {
		super(menu)

		this.logoGeometry = new THREE.PlaneGeometry()

		this.logoMaterial = new THREE.MeshBasicMaterial({
			opacity : 1.0,
			transparent : true
		})

		this.backGeometry = new THREE.PlaneGeometry()

		this.backMaterial = new THREE.MeshBasicMaterial({
			color : 0xffffff,
			opacity : 1.0,
			transparent : true

		})
			
		this.logo = new THREE.Mesh(this.logoGeometry, this.logoMaterial)
		this.back = new THREE.Mesh(this.backGeometry, this.backMaterial)

		this.logo.position.z = 4
		this.back.position.z = 3

		this.object.add(this.back)
		this.object.add(this.logo)
		this.object.position.z = -10

		new THREE.TextureLoader().load('/assets/img/loading.png', (texture) => {
			this.logoMaterial.map = texture
			this.logoMaterial.needsUpdate = true
			this.menu.onUpdate()
		})
	}

	setSize(cw, ch, cr, bw) {
		super.setSize(cw, ch, cr)

		this.back.scale.x = cw
		this.back.scale.y = ch

		let size = this.menu.isSP ? 500 : 500
		let base = this.menu.isSP ? 750 : 1440

		this.logo.scale.x = this.baseWidth * size / base
		this.logo.scale.y = this.logo.scale.x
	}

	fadeOut(duration, ease, onUpdate) {
		return new TimelineMax().add([
			this.menu.tween(this.backMaterial, duration, {
				ease     : ease,
				opacity  : 0,
			}),
			this.menu.tween(this.logoMaterial, duration, {
				ease     : ease,
				opacity  : 0,
				onUpdate : onUpdate
			})
		])
	}
}

export default Loading