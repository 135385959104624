
import noise from '../../shader/noise.glsl'
import util  from '../../shader/util.glsl'

export default function(mixer='') {

	return `
	uniform sampler2D text_textures[2];
	uniform sampler2D back_textures[10];

	uniform vec2 text_mapping_ratio;
	uniform vec2 text_mapping_offset;
	uniform vec2 back_mapping_ratio;
	uniform vec2 back_mapping_offset;

	uniform float time;
	uniform float seed;
	uniform float intensity;
	uniform int   text_index0;
	uniform int   text_index1;
	uniform int   back_index0;
	uniform int   back_index1;
	uniform float text_opacity;
	uniform float back_opacity;

	uniform int   hover_i;
	uniform float hover_t;
	uniform float hover_s;
	uniform float hover_o;

	varying vec2 vUv;

	${noise}

	${util}

	vec4 map_back(int i, float u, float v) {
		if (i == 0) {
			return texture2D(back_textures[0], vec2(u, v));
		} else if (i == 1) {
			return texture2D(back_textures[1], vec2(u, v));
		} else if (i == 2) {
			return texture2D(back_textures[2], vec2(u, v));
		} else if (i == 3) {
			return texture2D(back_textures[3], vec2(u, v));
		} else if (i == 4) {
			return texture2D(back_textures[4], vec2(u, v));
		} else if (i == 5) {
			return texture2D(back_textures[5], vec2(u, v));
		} else if (i == 6) {
			return texture2D(back_textures[6], vec2(u, v));
		} else if (i == 7) {
			return texture2D(back_textures[7], vec2(u, v));
		} else if (i == 8) {
			return texture2D(back_textures[8], vec2(u, v));
		} else if (i == 9) {
			return texture2D(back_textures[9], vec2(u, v));
		} else {
			return vec4(1.0, 1.0, 1.0, 1.0);
		}
	}

	vec4 map_text(int i, float u, float v) {
		if (i == 0) {
			return texture2D(text_textures[0], vec2(u, v));
		} else {
			return texture2D(text_textures[1], vec2(u, v));
		}
	}


	vec4 getTextColor() {
		vec4 text_color = vec4(0.0);

		if (text_index0 != -1) {
			float text_u = vUv.x * text_mapping_ratio.x + text_mapping_offset.x;
			float text_v = vUv.y * text_mapping_ratio.y + text_mapping_offset.y;

			// if (hover_x0 < vUv.x && vUv.x <= hover_x1) {
			// 	if (hover_i == 0) {
			// 		if (vUv.y < 1.5 / 91.0) {
			// 			text_color = vec4(.0, .0, .0, 1.0);
			// 		} else {
			// 			float du = 0.1 * (hover_x1 - hover_x0 - vUv.x) * pow(1.0 - vUv.y, 50.0);
			// 			text_color = map_text(text_index0, text_u - du, text_v);
			// 		}
			// 	} else {
			// 		float du = 0.1 * (hover_x1 - hover_x0 - vUv.x) * pow(0.0 + vUv.y, 50.0);
			// 		text_color = map_text(text_index0, text_u - du, text_v);
			// 	}
			// } else {
			// 	text_color = map_text(text_index0, text_u, text_v);
			// }

			text_color = map_text(text_index0, text_u, text_v);
			text_color.w *= text_opacity;
		}

		return text_color;
	}

	vec4 getBackColor() {
		vec4 back_color = vec4(1.0);

		float back_u = fract(vUv.x * back_mapping_ratio.x + back_mapping_offset.x);
		float back_v = fract(vUv.y * back_mapping_ratio.y + back_mapping_offset.y);

		${mixer}

		return back_color;
	}

	vec4 getHoverColor() {
		vec4 hover_color = vec4(0.0);

		if (text_index0 != -1 && hover_t > 0.0) {
			float text_u = vUv.x * text_mapping_ratio.x + text_mapping_offset.x;
			float text_v = vUv.y * text_mapping_ratio.y + text_mapping_offset.y;

			if (hover_t > 0.0) {
				text_u += 0.05 * getNoise8(vec2(text_u + hover_s, text_v + hover_s), hover_t * 0.05);
			}

			hover_color = map_text(text_index0, text_u, text_v);
			hover_color.w += pow(hover_color.w, 3.0);
			hover_color.w *= hover_o;
		}

		return hover_color;
	}


	void main() {
		if (hover_t > 0.0) {
			gl_FragColor = overlay(getHoverColor(), invert(getTextColor(), getBackColor()));
		} else {
			gl_FragColor = invert(getTextColor(), getBackColor());
		}
	}`
}