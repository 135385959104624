
import {
	LOADER_START,
	LOADER_PROGRESS,
	LOADER_SUCCESS,
	LOADER_FAILED
} from './types'


export const start = function() {
	return {type:LOADER_START}
}

export const progress = function(rate) {
	return {type:LOADER_PROGRESS, progress:rate}
}

export const success = function(payload) {
	return {type:LOADER_SUCCESS, payload:payload}
}

export const failed = function(error) {
	return {type:LOADER_FAILED, error:error}
}


export default {
	start    : start,
	progress : progress,
	success  : success,
	failed   : failed
}