
import noise from '../../shader/noise.glsl'

export default `

uniform vec2      size;
uniform float     time;
uniform float     seed;
uniform float     opacity;
uniform sampler2D texture;

varying vec2 vUv;

${noise}


void main() {
	if (vUv.x < 1.0 / size.x || vUv.y < 1.0 / size.y || vUv.x > (size.x - 1.0) / size.x || vUv.y > (size.y - 1.0) / size.y) {
		gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
	} else {
		if (time == 0.0) {
			;
		} else if (time == 1.0) {
			gl_FragColor = texture2D(texture, vUv);
		} else {
			float z = getNoise2(vUv + seed);
			float c = 2.0 * z * z * z * z;
			float q = c * (1.0 - time);

			vec4 color = texture2D(texture, vec2(vUv.x, vUv.y - q));

			color.w *= opacity;

			gl_FragColor = color;
		}
	}
}

`