
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/menu/actions'


class MenuRouter extends React.Component {

	componentDidUpdate(prevProps, prevState) {

		// メニュー用データのロード完了
		if (this.props.menudata !== prevProps.menudata && this.props.menudata) {
			const { pIndex, cIndex } = this.pathname2index(this.props.pathname)

			if (pIndex !== -1 || cIndex !== -1) {
				this.props.change(pIndex, cIndex)
			}
		}

		// パス変更でメニュー開閉
		if (this.props.pathname !== prevProps.pathname) {
			const { pIndex, cIndex } = this.pathname2index(this.props.pathname)

			if (pIndex !== -1 || cIndex !== -1) {
				this.props.change(pIndex, cIndex)
			} else {
				this.props.close()
			}
		}

		// ページのロードが完了したら、メニューにロード完了を伝達
		if (this.props.status !== prevProps.status && this.props.status === 'complete') {
			this.props.loaded()
		}
	}

	pathname2index(pathname) {
		let pIndex = -1
		let cIndex = -1

		this.props.menudata.menu.forEach((item, i) => {
			if (item.path === pathname) {
				pIndex = i
			} else {
				item.children && item.children.forEach((child, j) => {
					if (child.path === pathname) {
						pIndex = i
						cIndex = j
					}
				})
			}
		})

		return { pIndex, cIndex }
	}

	render() {
		return (
			<React.Fragment>
				{this.props.children}
			</React.Fragment>
		)
	}
}

function mapStateToProps(state) {
	return {
		pathname : state.router.location.pathname,
		menudata : state.loader.payload,
		status   : state.page.status
	}
}

function mapDispatchToProps(dispatch) {
	return {
		change : (pIndex, cIndex) => dispatch(actions.change(pIndex, cIndex)),
		loaded : () => dispatch(actions.loaded()),
		close  : () => dispatch(actions.close())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuRouter)