
import * as THREE from 'three'

import { TweenMax, TimelineMax, Power2, Power4, Expo } from 'gsap'

import Polaris    from 'lib/Polaris'
import Loading    from './loading'
import ImageSet   from './imageset'
import White      from './white'
import LineCursor from './linecursor'
import Logo       from './logo'
import Notice     from './notice'


export default class Menu {

	constructor(wrapper) {

		this.isSP = false

		this.wrapper = wrapper
		this.windowW = 0
		this.windowH = 0
		this.canvasW = 0
		this.canvasH = 0
		this.canvasR = 0

		this.scene    = new THREE.Scene()
		this.layer1   = new THREE.Object3D() // メニュー本体用
		this.layer2   = new THREE.Object3D() // 告知用
		this.camera   = new THREE.OrthographicCamera(0, 0, 0, 0, 0, 10000)
		this.renderer = new THREE.WebGLRenderer({alpha:true, antialias:true})

		this.scene.add(this.layer1)
		this.scene.add(this.layer2)

		this.dummyScroll = document.createElement('div')

		this.wrapper.appendChild(this.dummyScroll)
		this.wrapper.appendChild(this.renderer.domElement)

		this.props = {
			rotation : 0,
		}

		this.offset = {
			canvasW   : 0,
			canvasH   : 0,
			baseWidth : 0
		}

		// 展開中の親メニュー番号
		this.pIndex = -1

		// 展開中の子メニュー番号
		this.cIndex = -1

		// 子メニューを開いている親メニュー番号
		this.oIndex = -1

		// ホバー中の子メニュー番号
		this.hIndex = -1

		// 前に開いていた親メニュー番号
		this.prevIndex = -1

		// スクロール位置
		this.targetTop = 0
		this.scrollTop = 0

		// メニュー画像配列
		this.items = []	

		// 告知オブジェクト配列
		this.notices = []

		this.timeline = new TimelineMax()

		this.onUpdate = this.onUpdate.bind(this)
		this.onResize = this.onResize.bind(this)

		// リスナー関数保存用
		this.listeners = {}
		
		// iPhone用の高さ保存
		this.iPhoneMaxHeight = {}

		// 描画更新フラグ
		this.needsUpdate = true

		// ローディング画像
		this.loading = new Loading(this)

		// マウス判定候補オブジェクト
		this.hitCheckObjects = []

		this.start()
	}

	get resolution() {
		return Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)
	}

	get scrollTop() {
		return this.layer1.position.y / this.resolution
	}

	set scrollTop(val) {
		this.layer1.position.y = val * this.resolution
	}

	get targetTop() {
		return this._targetTop
	}

	set targetTop(val) {
		this._targetTop = val
		this.wrapper.scrollTop = val
	}

	set mode(val) {
		this.isSP = (val.toLowerCase() === 'sp')
		this.onResize()
	}

	get MAX_WIDTH() {
		return 1440
	}

	get MIN_WIDTH() {
		return 320
	}

	get baseWidth() {
		return Polaris.util.clamp(this.windowW, this.MIN_WIDTH, this.MAX_WIDTH) * this.resolution
	}

	on(event, callback) {
		if (event in this.listeners) {
			this.listeners[event].push(callback)
		} else {
			this.listeners[event] = [callback]
		}
	}

	progress(rate) {
	}

	loadMenu(data) {
		// メニュー画像作成
		this.items = data.menu.map((prop, i) => {
			return new ImageSet(this, prop, [data.back[0].back_image.texture], i)
		})

		// 背景隠し
		this.whiteU = new White(this, data.back[0].back_image.texture)
		this.whiteD = new White(this, data.back[0].back_image.texture)

		// ロゴ
		this.logo = new Logo(this, data.logo, data.back[0].back_image.texture)

		// マウスカーソル作成
		this.cursor = new LineCursor(this)

		// 描画順の調整（数値が小さいほど奥に配置）
		this.logo.zIndex    = -102
		this.whiteU.zIndex  = -102
		this.whiteD.zIndex  = -102
		this.cursor.zIndex  = -100
		this.loading.zIndex = -10

		this.items.forEach((item, i) => {
			item.zIndex = -102

			item.children.forEach((child, j) => {
				child.zIndex = -200
			})
		})

		// クリック検査候補に追加
		this.logo.object.children[0].clickParam = { name:'logo', param:{ pIndex:-1, cIndex:-1 } }
		this.hitCheckObjects.push(this.logo.object)

		this.items.forEach((item, i) => {
			item.object.children[0].clickParam = { name:'menu', param:{ pIndex:i, cIndex:-1 } }
			item.object.children[1].clickParam = { name:'menu', param:{ pIndex:i, cIndex:-1 } }
			this.hitCheckObjects.push(item.object)

			item.children.forEach((child, j) => {
				child.object.children[0].clickParam = { name:'menu', param:{ pIndex:i, cIndex:j } }
				child.object.children[1].clickParam = { name:'menu', param:{ pIndex:i, cIndex:j } }
				this.hitCheckObjects.push(child.object)
			})
		})

		this.onResize()

		this.loading.fadeOut(0.8, Power2.easeOut, this.onUpdate).call(() => {
			this.onResize()

			this.notices.forEach((notice, i) => {
				notice.delay(1.0 * i).show(1.0, Power2.easeInOut)
			})
		})
	}


	loadNotices(notices) {

		this.notices = notices.map((prop, i) => {
			return new Notice(this, prop)
		})

		// 描画順の調整（数値が小さいほど奥に配置）
		this.notices.forEach((notice, i) => {
			notice.zIndex = -10 * i
		})

		// クリック検査候補に追加
		this.notices.forEach((notice, i) => {
			notice.hitArea.clickParam   = { name:'notice', param:{ nIndex:i } }
			notice.close.clickParam = { name:'close',  param:{ nIndex:i } }
			this.hitCheckObjects.push(notice.hitArea)
			this.hitCheckObjects.push(notice.close)
		})

		this.onResize()
	}

	calcMaxHeight() {

		let h = this.baseWidth * this.items.reduce((height, item, i) => {
			if (i === this.oIndex) {
				return height + item.HEIGHT + (item.HEIGHT * item.children.length + item.OFFSET * 2)
			} else {
				return height + item.HEIGHT
			}
		}, 0)

		// ロゴ分の高さ
		if (this.logo) {
			h += this.logo.image.mesh.scale.y
		}

		return Math.max(this.windowH, h / this.resolution)
	}

	onUpdate() {
		this.needsUpdate = true
	}

	onResize() {

		this.windowW = this.wrapper.clientWidth

		if (Polaris.ua.iphone) {
			this.iPhoneMaxHeight[window.orientation] = Math.max(window.innerHeight, this.iPhoneMaxHeight[window.orientation] || 0)
			this.windowH = this.iPhoneMaxHeight[window.orientation]
		} else {
			this.windowH = this.wrapper.clientHeight
		}

		this.dummyScroll.style.height = this.calcMaxHeight() + 'px'

		this.canvasW = this.windowW * this.resolution
		this.canvasH = this.windowH * this.resolution
		this.canvasR = Math.sqrt(this.canvasW * this.canvasW + this.canvasH * this.canvasH) / 2

		this.camera.left   = this.canvasW / -2
		this.camera.right  = this.canvasW / +2
		this.camera.top    = this.canvasH / +2
		this.camera.bottom = this.canvasH / -2
		this.camera.updateProjectionMatrix()

		this.renderer.setSize(this.canvasW, this.canvasH)
		this.renderer.domElement.style.width  = this.windowW + 'px'
		this.renderer.domElement.style.height = this.windowH + 'px'

		if (this.items.length > 0) {

			this.items.forEach((item) => {
				item.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)
			})

			this.notices.forEach((notice) => {
				notice.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)
			})

			this.logo.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)
			this.whiteU.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)
			this.whiteD.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)
			this.cursor.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)

			// 背景画像の位置調整
			const size = Math.max(this.canvasW, this.canvasH)

			const yU = 0.5 + this.canvasH / size / 2

			const yD = this.items.reduce((y, item, i) => {
				item.imageU.material.uniforms.back_mapping_ratio.value.x  = item.imageU.mesh.scale.x / size
				item.imageU.material.uniforms.back_mapping_ratio.value.y  = item.imageU.mesh.scale.y / size
				item.imageU.material.uniforms.back_mapping_offset.value.x = - (this.canvasR - size / 2) / size
				item.imageU.material.uniforms.back_mapping_offset.value.y = (y -= item.HEIGHT_U * this.baseWidth / size)

				item.imageD.material.uniforms.back_mapping_ratio.value.x  = item.imageD.mesh.scale.x / size
				item.imageD.material.uniforms.back_mapping_ratio.value.y  = item.imageD.mesh.scale.y / size
				item.imageD.material.uniforms.back_mapping_offset.value.x = - (this.canvasR - size / 2) / size
				item.imageD.material.uniforms.back_mapping_offset.value.y = (y -= item.HEIGHT_D * this.baseWidth / size)
				return y
			}, yU)

			this.whiteU.image.material.uniforms.back_mapping_offset.value.y = yU
			this.whiteD.image.material.uniforms.back_mapping_offset.value.y = yD - 2 * this.canvasR / size

			if (this.isSP) {
				this.logo.yD = yD
			} else {
				this.logo.image.material.uniforms.back_mapping_offset.value.y = yD - this.logo.image.mesh.scale.y / size
			}
		}

		this.loading.setSize(this.canvasW, this.canvasH, this.canvasR, this.baseWidth)

		
		this.layer2.visible = !this.isSP

		this.onUpdate()
	}

	update() {
		if (this.items.length > 0) {

			const y0 = this.offset.canvasW * this.canvasW
					 + this.offset.canvasH * this.canvasH
					 + this.offset.baseWidth * this.baseWidth + 1

			const y1 = this.items.reduce((y, item) => {
				return item.next(y)
			}, y0)

			this.whiteU.draw(y0, +1)
			this.whiteD.draw(y1, -1)

			this.updateLogo(y0, y1)

			this.layer1.rotation.z = this.props.rotation
		}
	}


	updateLogo(y0, y1) {
		if (this.isSP) {
			const size = Math.max(this.canvasW, this.canvasH)
			const h1 = this.logo.yD - this.logo.image.mesh.scale.y / size
			const h2 = 1.0 + this.items.reduce((h, item) => h + item.height, 0) / size + y0 / size
			const y2 = window.innerHeight * this.resolution - this.logo.image.mesh.scale.y

			if (y2 > y1) {
				this.logo.draw(y2)
				this.logo.image.material.uniforms.back_mapping_offset.value.y = h2
			} else {
				this.logo.draw(y1)
				this.logo.image.material.uniforms.back_mapping_offset.value.y = h1
			}
		} else {
			this.logo.draw(y1)
		}
	}


	start() {

		this.resizeID = Polaris.util.onResize((w, h) => {
			this.onResize()
		})

		this.wrapper.addEventListener('scroll', (e) => {
			this.targetTop = this.wrapper.scrollTop
		})

		TweenMax.ticker.addEventListener('tick', () => {

			if (this.scrollTop !== this.targetTop) {
				this.needsUpdate = true

				this.scrollTop += (this.targetTop - this.scrollTop) * 0.2

				if (Math.abs(this.scrollTop - this.targetTop) < 0.1) {
					this.scrollTop = this.targetTop
				}
			}

			if (this.needsUpdate) {
				this.update()
				this.renderer.render(this.scene, this.camera)
			}
			this.needsUpdate = false
		})

		this.renderer.domElement.addEventListener("webglcontextlost", function(e) {
			window.location.reload()
		})
	}

	clearTimeline() {
		this.timeline.kill()
		this.timeline.clear()
	}

	// 選択リンクを中央に移動
	select(pIndex, cIndex) {
		this.clearTimeline()

		this.pIndex = pIndex
		this.cIndex = cIndex
		this.oIndex = -1

		this.prevIndex = pIndex
		this.cursor.visible = false

		if (cIndex === -1) {
			this.selectParent(pIndex, 0.7)
		} else {
			this.selectChild(pIndex, cIndex, 0.7)
		}
	}

	// 開く
	expand(pIndex, cIndex, ready, finish) {

		this.pIndex = pIndex
		this.cIndex = cIndex
		this.oIndex = -1

		// 開く準備ができたときに実行
		this.timeline.call(ready).add(TweenMax.to({}, 0.2))

		// 確実にselectが終了した状態にする
		this.timeline.call(() => {
			this.props.rotation = -Math.PI/2
			this.onResize()
		})

		if (cIndex === -1) {
			this.expandParent(pIndex)
		} else {
			this.expandChild(pIndex, cIndex)
		}

		this.timeline.call(finish)
	}

	selectParent(pIndex, duration=0.7) {
		const queue1 = []
		const queue2 = []
		const item   = this.items[0]

		// 中心に移動
		queue1.push(this.tween(this.offset, duration, {
			ease      : Expo.easeOut,
			canvasW   : 0.0,
			canvasH   : 0.5,
			baseWidth : -1 * (item.HEIGHT_U + pIndex * item.HEIGHT),
		}))
		
		// トップにスクロール
		queue1.push(this.tween(this, duration, {
			ease      : Expo.easeOut,
			targetTop : 0,
			scrollTop : 0
		}))

		// 開いているものをすべて閉じる
		this.items.forEach((item) => {
			queue1.push(...item.closeSubmenu(duration, Expo.easeOut))

			item.children.forEach((child) => {
				queue1.push(...child.closeSubmenu(duration, Expo.easeOut))
			})
		})

		// 回転
		if (this.props.rotation === 0) {
			queue2.push(this.tween(this.props, duration, {
				ease     : Power4.easeInOut,
				rotation : -Math.PI/2
			}))

			queue2.push(this.tween(this.offset, duration, {
				ease      : Expo.easeOut,
				canvasW   : 0.0,
				canvasH   : 0.5,
				baseWidth : -1 * (item.HEIGHT_U + pIndex * item.HEIGHT),
			}))
		}

		this.timeline.add(queue1).call(this.onResize).add(queue2)
	}

	expandParent(pIndex) {
		const queue1 = []
		const item   = this.items[0]

		// 中心に来るように移動
		queue1.push(this.tween(this.offset, 1.0, {
			ease      : Expo.easeOut,
			canvasW   : -0.5,
			canvasH   : 0.5,
			baseWidth : -1 * (pIndex * item.HEIGHT - item.MARGIN - item.OFFSET),
		}))

		// ページを開く
		queue1.push(...this.items[pIndex].openGap(1.0, Expo.easeOut))

		this.timeline.add(queue1)
	}

	selectChild(pIndex, cIndex, duration=0.7) {
		const queue1 = []
		const queue2 = []
		const item   = this.items[0]

		// 背景を変更
		if (this.hIndex !== cIndex) {
			this.items[pIndex].changeTexture(cIndex, 2.0, Power2.easeOut)
		}

		// 選択したメニューを表示、その他は閉じる
		this.items.forEach((item, i) => {
			if (i === pIndex) {
				queue1.push(...item.selectChild(cIndex, duration, Expo.easeOut))
			} else {
				queue1.push(...item.closeSubmenu(duration, Expo.easeOut))
			}

			item.children.forEach((child) => {
				queue1.push(...child.closeSubmenu(duration, Expo.easeOut))
			})
		})

		// 中心に来るように移動
		queue1.push(this.tween(this.offset, duration, {
			ease      : Expo.easeOut,
			canvasW   : 0.0,
			canvasH   : 0.5,
			baseWidth : -1 * (item.HEIGHT_U + item.HEIGHT_D + pIndex * item.HEIGHT),
		}))

		// トップにスクロール
		queue1.push(this.tween(this, duration, {
			ease      : Expo.easeOut,
			targetTop : 0,
			scrollTop : 0
		}))

		// 回転
		if (this.props.rotation === 0) {
			queue2.push(this.tween(this.props, duration, {
				ease     : Power4.easeInOut,
				rotation : -Math.PI/2
			}))

			queue2.push(this.tween(this.offset, duration, {
				ease      : Expo.easeOut,
				canvasW   : 0.0,
				canvasH   : 0.5,
				baseWidth : -1 * (item.HEIGHT_U + item.HEIGHT_D + pIndex * item.HEIGHT),
			}))
		}

		this.timeline.add(queue1).call(this.onResize).add(queue2)
	}

	expandChild(pIndex, cIndex) {
		const queue1 = []
		const item   = this.items[0]

		// 中心に来るように移動
		queue1.push(this.tween(this.offset, 1.0, {
			ease      : Expo.easeOut,
			canvasW   : -0.5,
			canvasH   : 0.5,
			baseWidth : -1 * (pIndex * item.HEIGHT + item.HEIGHT_U - item.OFFSET),
		}))

		// ページを開く
		queue1.push(...this.items[pIndex].children[cIndex].openGap(1.0, Expo.easeOut))

		this.timeline.add(queue1)
	}

	openSubmenu(pIndex) {
		this.clearTimeline()

		// パターン1. 前に子メニューが展開されていた場合
		// (1) メニュー選択 => (2) 回転を戻す & 開く & スクロール位置調整

		// パターン2. 前に他の親メニューが展開されていた場合
		// (1) 閉じる => (2) 回転を戻す => (3) 開く & スクロール位置調整

		// パターン3. 普通に開く場合
		// (1) 開く & スクロール位置調整

		const queue1 = []
		const queue2 = []
		const queue3 = []

		// 背景アニメーション
		if (this.hIndex === -1) {
			this.hIndex = 0
		}
		if (this.oIndex !== pIndex) {
			this.hIndex = 0
		}
		if (this.cIndex === -1) {
			this.items[pIndex].changeTexture(this.hIndex, 1.5, Power2.easeOut)
		}

		this.oIndex = pIndex
		
		if (this.cIndex !== -1) {
			// パターン1. 前に子メニューが開いていた場合

			// (1) メニュー選択
			this.selectChild(this.pIndex, this.cIndex, 0.6)

			// (2) 回転
			queue2.push(this.tween(this.props, 0.7, {
				ease     : Power4.easeOut,
				rotation : 0
			}))

			// (3)
			this.items.forEach((item, i) => {
				if (i === pIndex) {
					queue3.push(...item.openSubmenu(0.7, Expo.easeOut))
				} else {
					queue3.push(...item.closeSubmenu(0.7, Expo.easeOut))
				}

				item.children.forEach((child) => {
					queue3.push(...child.closeSubmenu(0.7, Expo.easeOut))
				})
			})

			this.scrollCenter(queue3, pIndex)

		
		} else if (this.pIndex !== -1) {
			// パターン2. 前に他の親メニューが展開されていた場合

			// (1) 中心移動
			this.selectParent(this.pIndex, 0.6)

			// (2) 回転
			queue2.push(this.tween(this.props, 0.6, {
				ease     : Power4.easeInOut,
				rotation : 0
			}))

			// (3) 開く
			queue3.push(...this.items[pIndex].openSubmenu(0.7, Expo.easeOut))

			// (3) スクロール位置調整
			this.scrollCenter(queue3, pIndex)

		} else {
			// パターン3. 普通に開く場合

			// (1) 開く
			this.items.forEach((item, i) => {
				if (i === pIndex) {
					queue1.push(...item.openSubmenu(0.7, Expo.easeOut))
				} else {
					queue1.push(...item.closeSubmenu(0.7, Expo.easeOut))
				}

				item.children.forEach((child) => {
					queue1.push(...child.closeSubmenu(0.7, Expo.easeOut))
				})
			})

			// (1) スクロール位置調整
			this.scrollCenter(queue1, pIndex)
		}

		this.pIndex = -1
		this.cIndex = -1

		this.onResize()

		this.timeline.add(queue1).add(queue2).add(queue3).call(() => {
			this.props.rotation = 0
			this.cursor.visible = true
			this.onResize()
		})
	}

	scrollCenter(queue, pIndex) {
		let top = 0

		const item = this.items[pIndex]

		const hh = item.HEIGHT
		const ho = item.OFFSET
		const hu = item.HEIGHT_U
		
		const openedHeight = (ho * 2 + item.children.length * hh) * this.baseWidth / this.resolution

		if (openedHeight > this.windowH) {
			top = pIndex * hh * this.baseWidth / this.resolution
		} else {
			top = (pIndex * hh + hu + (ho * 2 + item.children.length * hh) / 2) * this.baseWidth / this.resolution - this.windowH / 2
		}

		queue.push(this.tween(this, 0.7, {
			targetTop : Math.max(0, top),
			scrollTop : Math.max(0, top),
			ease      : Expo.easeOut,
		}))

		queue.push(this.tween(this.offset, 0.5, {
			ease      : Expo.easeOut,
			canvasW   : 0,
			canvasH   : 0,
			baseWidth : 0,
		}))
	}

	close() {
		this.clearTimeline()

		const queue1 = []
		const queue2 = []
		const item   = this.items[0]
		const prev   = this.pIndex

		this.pIndex = -1
		this.cIndex = -1
		this.oIndex = -1

		// スクロール
		queue1.push(this.tween(this, 0.6, {
			ease      : Expo.easeOut,
			targetTop : 0,
			scrollTop : 0,
		}))

		// 位置をもとに戻す
		queue1.push(this.tween(this.offset, 0.6, {
			ease      : Expo.easeOut,
			canvasW   : 0,
			canvasH   : prev === -1 ? 0 : 0.5,
			baseWidth : prev === -1 ? 0 : -1 * (item.HEIGHT_U + prev * item.HEIGHT),
		}))

		// 開いているものをすべて閉じる
		this.items.forEach((item) => {
			queue1.push(...item.closeSubmenu(0.6, Expo.easeOut))

			item.children.forEach((child) => {
				queue1.push(...child.closeSubmenu(0.6, Expo.easeOut))
			})
		})

		// 回転をもとに戻す
		if (this.props.rotation !== 0) {
			queue2.push(this.tween(this.props, 0.5, {
				ease     : Power4.easeOut,
				rotation : 0
			}))
		}

		// 一番上に持ってくる
		queue2.push(this.tween(this.offset, 0.5, {
			ease      : Expo.easeOut,
			canvasW   : 0,
			canvasH   : 0,
			baseWidth : 0,
		}))

		this.onResize()

		this.timeline.add(queue1).add(queue2).call(() => {
			this.cursor.visible = true
			this.onUpdate()
		})
	}

	getHitObject(x, y) {
		const mouse = new THREE.Vector2(
			2 * x / this.windowW - 1,
			1 - 2 * (y - this.scrollTop) / this.windowH
		)

		const raycaster = new THREE.Raycaster()

		raycaster.setFromCamera(mouse, this.camera)

		return raycaster.intersectObjects(this.hitCheckObjects, true).reduce((object, hit) => {
			if (this.isSP && hit.object.clickParam.name === 'notice') {
				return object
			} else {
				if (object) {
					return hit.distance < object.distance ? hit : object
				} else {
					return hit
				}
			}
		}, null)
	}

	callListener(eventType, clickParam) {
		if (clickParam) {
			const name = eventType + ':' + clickParam.name

			if (name in this.listeners) {
				this.listeners[name].forEach((callback) => {
					callback(clickParam.param)
				})
			}
		}
	}

	click(x, y) {
		const hit = this.getHitObject(x, y)

		if (hit) {
			this.callListener('click', hit.object.clickParam)
		}
	}

	distort() {
		const seed = Math.random()
		const time = 1.5
		const ease = Power4.easeOut

		this.items.forEach((item) => {
			item.imageU.distort(true, 0.5, seed, time, ease)
			item.imageD.distort(true, 0.5, seed, time, ease)
		})

		this.whiteU.image.distort(true, 0.5, seed, time, ease)
		this.whiteD.image.distort(true, 0.5, seed, time, ease)
		this.logo.image.distort(true, 0.5, seed, time, ease)
	}

	wheel(animateChild) {
		const seed = Math.random()
		const time = 1.2
		const ease = Power2.easeOut

		this.items.forEach((item) => {
			item.imageU.distort(false, 0.2, seed, time, ease)
			item.imageD.distort(false, 0.2, seed, time, ease)
			// item.imageU.hover(time, ease)
			// item.imageD.hover(time, ease)

			if (animateChild) {
				item.children.forEach((child) => {
					child.imageU.distort(false, 0.2, seed, time, ease)
					child.imageD.distort(false, 0.2, seed, time, ease)
					// child.imageU.hover(time, ease)
					// child.imageD.hover(time, ease)
				})
			}
		})

		this.whiteU.image.distort(false, 0.2, seed, time, ease)
		this.whiteD.image.distort(false, 0.2, seed, time, ease)
		this.logo.image.distort(false, 0.2, seed, time, ease)
	}

	hover(pIndex, cIndex) {
		const time = 0.8
		const ease = Power2.easeOut

		if (cIndex === -1) {
			this.items[pIndex].imageU.hover(time, ease)
			this.items[pIndex].imageD.hover(time, ease)
		} else {
			this.items[pIndex].children[cIndex].imageU.hover(time, ease)
			this.items[pIndex].children[cIndex].imageD.hover(time, ease)
		}
	}

	hoverLogo() {
		const time = 0.8
		const ease = Power2.easeOut
		this.logo.image.hover(time, ease)
	}

	mousemove(x, y) {
		this.cursor.y = y

		if (this.props.rotation === 0) {
			this.needsUpdate = true

			const hit = this.getHitObject(x, y)
			
			if (hit) {
				this.callListener('hover', hit.object.clickParam)
			}
		}
	}

	changeTexture(pIndex, cIndex) {
		this.hIndex = cIndex
		this.items[pIndex].changeTexture(cIndex, 1.5, Power2.easeOut)
	}

	closeNotice(index) {
		if (index in this.notices) {
			this.notices[index].hide(1.0, Power2.easeInOut)
		}
	}

	// TweenMax.to実行時に、needsUpdateフラグを立てる
	tween(target, duration, param) {

		param.onUpdate = this.onUpdate

		return TweenMax.to(target, duration, param)
	}
}