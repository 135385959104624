
import {
	MENU_CHANGE,
	MENU_LOADED,
	MENU_READY,
	MENU_FINISH,
	MENU_CLOSE,
} from './types'


export const change = function(pIndex, cIndex) {
	return {type:MENU_CHANGE, pIndex:pIndex, cIndex:cIndex}
}

export const loaded = function() {
	return {type:MENU_LOADED}
}

export const ready = function() {
	return {type:MENU_READY}
}

export const finish = function() {
	return {type:MENU_FINISH}
}

export const close = function() {
	return {type:MENU_CLOSE}
}

export default {
	change,
	loaded,
	ready,
	finish,
	close,
}