
import noise from '../../shader/noise.glsl'
import util  from '../../shader/util.glsl'

export default function() {
	return `
	uniform sampler2D image_texture;
	uniform sampler2D close_texture;

	uniform vec2 image_pos;
	uniform vec2 image_size;
	uniform vec2 close_pos;
	uniform vec2 close_size;
	uniform float time;
	uniform float seed;
	uniform float opacity;

	varying vec2 vUv;

	${noise}

	${util}


	void main() {
		float x0, x1, y0, y1;

		x0 = image_pos.x;
		x1 = image_pos.x + image_size.x;
		y0 = 1.0 - image_pos.y - image_size.y;
		y1 = 1.0 - image_pos.y;

		float image_u = (vUv.x - x0) / (x1 - x0);
		float image_v = (vUv.y - y0) / (y1 - y0);

		x0 = close_pos.x;
		x1 = close_pos.x + close_size.x;
		y0 = 1.0 - close_pos.y - close_size.y;
		y1 = 1.0 - close_pos.y;

		float close_u = (vUv.x - x0) / (x1 - x0);
		float close_v = (vUv.y - y0) / (y1 - y0);

		if (-0.2 < image_u && image_u < 1.2 && -0.2 < image_v && image_v < 1.2) {

			if (time > 0.0 && time < 1.0) {
				// float nx = getNoise4(vec3(image_u, image_v, seed + 0.0));
				// float ny = getNoise4(vec3(image_u, image_v, seed + 1.0));

				float nx = getNoise4(vec2(image_u + seed, image_v));
				float ny = getNoise4(vec2(image_u, image_v + seed));

				image_u = image_u + nx * 0.5 * time;
				image_v = image_v + ny * 0.5 * time;

				close_u = close_u + nx * 0.5 * time;
				close_v = close_v + ny * 0.5 * time;
			}

			if (0.0 <= image_u && image_u <= 1.0 && 0.0 <= image_v && image_v <= 1.0) {
				vec4 image_color = texture2D(image_texture, vec2(image_u, image_v));

				if (0.0 < close_u && close_u < 1.0 && 0.0 < close_v && close_v < 1.0) {
					vec4 close_color = texture2D(close_texture, vec2(close_u, close_v));
					gl_FragColor = vec4(blend(close_color, image_color).xyz, opacity);
				} else {
					gl_FragColor = vec4(image_color.xyz, opacity);
				}
			}
		}
	}`
}