
import React from 'react'

import Polaris from 'lib/Polaris'

import styles from './scrollbar.module.scss'


class Scrollbar extends React.Component {

	scrollbar = React.createRef()


	componentDidMount() {
		const computedStyle = window.getComputedStyle(this.scrollbar.current, '::before');

		Polaris.util.onResize(() => {
			window.scrollBarWidth = computedStyle.getPropertyValue('width')
		})
	}

	render() {
		return (
			<div ref={this.scrollbar} className={styles.scrollbar}></div>
		)
	}
}

export default Scrollbar
