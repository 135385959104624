
import React from 'react'

import styles from './section.module.scss'


const Section = (props) => (
	<section className={[styles.section, props.className].join(' ')}>
		{props.children}
	</section>
)

export default Section
