
import {
	IMAGES_MODAL_OPEN,
	IMAGES_MODAL_CLOSE
} from './types'


export const openModal = function(index) {
	return {type:IMAGES_MODAL_OPEN, index:index}
}

export const closeModal = function() {
	return {type:IMAGES_MODAL_CLOSE}
}

export default {
	openModal : openModal,
	closeModal : closeModal
}