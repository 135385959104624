
import * as THREE from 'three'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import Image from './image'


export default class Exhibition {

	constructor(wrapper, items, device) {
		this.wrapper = wrapper
		this.canvasW = 0
		this.canvasH = 0

		this.targetTop = null
		this.scrollTop = null
		this.offsetTop = null

		this.onResize = this.onResize.bind(this)
		this.onScroll = this.onScroll.bind(this)
		this.onFrame  = this.onFrame.bind(this)

		// iPhone用の高さ保存
		this.iPhoneMaxHeight = {}

		this.scene    = new THREE.Scene()
		this.camera   = new THREE.OrthographicCamera(0, 0, 0, 0, -10000, 10000)
		this.renderer = new THREE.WebGLRenderer({alpha:true, antialias:true})

		this.hovering  = -1
		this.mouse     = new THREE.Vector2()
		this.raycaster = new THREE.Raycaster()

		this.wrapper.appendChild(this.renderer.domElement)

		this.images = items.map((item) => {
			return new Image(item)
		})

		// 配置決め
		this.images.reduce((carry, image) => {
			let minX, maxX, minY, maxY

			this.scene.add(image.object)

			// 横方向
			if (image.size === 4) {
				minX = -15
				maxX = +15
			} else if (image.size === 3) {
				minX = -3
				maxX = +3
			} else {
				if (carry.left) {
					// 左配置
					if (image.size === 1) {
						minX = -13
						maxX = -8
					}
					if (image.size === 2) {
						minX = -23
						maxX = -15
					}
				} else {
					// 右配置
					if (image.size === 1) {
						minX = 8
						maxX = 13
					}
					if (image.size === 2) {
						minX = 15
						maxX = 23
					}
				}
			}

			// 縦方向
			if (carry.size === -1) {
				minY = 20
				maxY = 25
			} else {
				if (image.size === 1) {
					if (carry.left) {
						minY = -20
						maxY = -10
					} else {
						minY = -50
						maxY = -20
					}
				} else if (image.size === 2) {
					if (carry.left) {
						minY = -20
						maxY = -10
					} else {
						minY = -90
						maxY = -60
					}
				} else if (image.size === 3) {
					minY = -10
					maxY = 5
				} else if (image.size === 4) {
					minY = -10
					maxY = 5
				}
			}
			

			image.marginL = Polaris.util.rand(minX, maxX) / 100
			image.marginT = Polaris.util.rand(minY, maxY) / 100

			if (image.size === 3 || image.size === 4) {
				return {size:image.size, left:true}
			} else {
				return {size:image.size, left:!carry.left}
			}
			
		}, {size:-1, left:true})

		this.start()

		this.device = device
	}

	get resolution() {
		return Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)
	}

	set device(val) {
		this.images.forEach((image) => {
			image.isSP = (val === 'sp')
		})
		this.onResize()
	}

	onResize() {
		this.windowW = this.wrapper.clientWidth

		if (Polaris.ua.iphone) {
			this.iPhoneMaxHeight[window.orientation] = Math.max(window.innerHeight, this.iPhoneMaxHeight[window.orientation] || 0)
			this.windowH = this.iPhoneMaxHeight[window.orientation]
		} else {
			this.windowH = this.wrapper.clientHeight
		}

		this.canvasW = this.windowW * this.resolution
		this.canvasH = this.windowH * this.resolution

		this.camera.left   = this.canvasW / -2
		this.camera.right  = this.canvasW / +2
		this.camera.top    = this.canvasH / +2
		this.camera.bottom = this.canvasH / -2
		this.camera.updateProjectionMatrix()

		this.renderer.setSize(this.canvasW, this.canvasH)
		this.renderer.domElement.style.width  = this.windowW + 'px'
		this.renderer.domElement.style.height = this.windowH + 'px'

		this.maxY = this.images.reduce((y, image, i) => image.resize(this.canvasW, this.canvasH, this.resolution, y), 0)

		this.wrapper.parentNode.parentNode.style.height = this.maxY + 'px'
	}

	onScroll(t) {
		this.targetTop = t

		if (this.scrollTop === null) {
			this.scrollTop = this.targetTop
		}
	}

	onFrame(ct, dt, pt) {
		this.scrollTop += (this.targetTop - this.scrollTop) * Math.min(dt, 50) * 0.01

		if (Math.abs(this.scrollTop - this.targetTop) < 0.1) {
			this.scrollTop = this.targetTop
		}

		this.offsetTop = Polaris.util.clamp(this.scrollTop - $(this.wrapper.parentNode.parentNode).offset().top, 0, this.maxY - this.canvasH)

		this.images.forEach((image) => {
			image.scroll(this.scrollTop, this.offsetTop)
			image.update(dt)
		})

		this.renderer.render(this.scene, this.camera)
	}

	start() {
		this.resizeID = Polaris.util.onResize(this.onResize)
		this.scrollID = Polaris.util.onScroll(this.onScroll)
		this.frameID  = Polaris.util.onFrame(this.onFrame)
	}

	destroy() {
		Polaris.util.offResize(this.resizeID)
		Polaris.util.offScroll(this.scrollID)
		Polaris.util.offFrame(this.frameID)

		this.images.forEach((image) => {
			image.destroy()
		})

		this.wrapper.removeChild(this.renderer.domElement)

		this.scene.dispose()
		this.renderer.dispose()

		this.wrapper  = null
		this.scene    = null
		this.camera   = null
		this.renderer = null
	}


	hover(x, y) {
		const index = this.isHit(x, y)

		if (index !== this.hovering && this.hovering !== -1) {
			this.images[this.hovering].scaleDown()
		}

		if (index !== -1) {
			if (index !== this.hovering) {
				this.hovering = index
				this.images[index].object.position.z = this.images.reduce((z, image) => Math.max(z, image.object.position.z), -99999) + 1
				this.images[index].scaleUp()
			}
		} else {
			this.hovering = -1
		}

		return index
	}

	click(x, y) {
		return this.isHit(x, y)
	}

	isHit(x, y) {
		this.mouse.x = 2 * x / this.windowW - 1
		this.mouse.y = 1 - 2 * y / this.windowH

		this.raycaster.setFromCamera(this.mouse, this.camera)

		return this.images.reduce((hitIndex, image, i) => {

			const intersects = this.raycaster.intersectObject(image.object, true)

			if (intersects.length > 0) {
				if (hitIndex === -1 || image.object.position.z > this.images[hitIndex].object.position.z) {
					return i
				}
			}
			return hitIndex
		}, -1)
	}
}