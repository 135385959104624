
import {
	FETCH_PAGE,
	FETCH_PAGE_START,
	FETCH_PAGE_SUCCESS,
	FETCH_PAGE_FAILED
} from './types'


const initialState = {
	path    : null,
	status  : null,
	payload : null,
	error   : null,
	reason  : null,
}


export default function pageReducer(state = initialState, action) {

	switch (action.type) {
		case FETCH_PAGE:
			return {...state, status:'fetch', path:action.path, error:null, reason:action.reason}

		case FETCH_PAGE_START:
			return {...state, status:'start'}

		case FETCH_PAGE_SUCCESS:
			return {...state, status:'complete', payload:action.payload}

		case FETCH_PAGE_FAILED:
			return {...state, status:'error', payload:{}, error:action.error}

		default:
			return state

	}
}