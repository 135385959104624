
import React from 'react'

import { Section, Container, Title } from 'views/components/common/section/index'

import Slide from './slide'

import styles from './index.module.scss'


const Items = (props) => (
	<Section className={styles.items}>
		<Container className={styles.container}>
		{props.payload.item_groups.map((group, i) => (
			<div key={i}>
				<Title srcSP={group.subtitle_image_sp.src} srcPC={group.subtitle_image_pc.src} alt={group.subtitle_image_pc.alt} />
				
				<div className={styles.content}>
					<Slide items={group.items} />
				</div>
			</div>
		))}
		</Container>
	</Section>
)

export default Items