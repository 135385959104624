
import { all } from 'redux-saga/effects'

import pageSaga from './page/sagas'

import loaderSaga from './loader/sagas'


export default function* rootSaga() {
	yield all([
		...pageSaga,
		...loaderSaga
	]);
}