
import * as THREE from 'three'

import { TimelineMax } from 'gsap'

import image_vert from './shaders/image_vert.glsl'
import image_frag from './shaders/image_frag.glsl'


export default class Image {

	constructor(menu, text_textures, back_textures, mixer='') {

		this.menu = menu

		this.geometry = new THREE.PlaneGeometry()

		this.material = new THREE.ShaderMaterial({
			uniforms : {
				text_textures : { value : text_textures, type:'tv' },
				back_textures : { value : back_textures, type:'tv' },

				text_mapping_ratio  : { value : new THREE.Vector2(1, 1) },
				text_mapping_offset : { value : new THREE.Vector2(0, 0) },
				back_mapping_ratio  : { value : new THREE.Vector2(1, 1) },
				back_mapping_offset : { value : new THREE.Vector2(0, 0) },

				seed         : { value : 0.0 },
				time         : { value : 0.0 },
				intensity    : { value : 1.0 },
				text_index0  : { value : 0 },
				text_index1  : { value : 0 },
				back_index0  : { value : 0 },
				back_index1  : { value : 0 },
				text_opacity : { value : 1.0 },
				back_opacity : { value : 1.0 },

				hover_i : { value : 0 },
				hover_t : { value : 0.0 },
				hover_s : { value : 0.0 },
				hover_o : { value : 0.0 },
			},
			vertexShader   : image_vert(),
			fragmentShader : image_frag(mixer),
			transparent    : true
		})

		this.mesh = new THREE.Mesh(this.geometry, this.material)

		this.textTimeline = new TimelineMax()
		this.backTimeline = new TimelineMax()
	}

	set visible(val) {
		this.mesh.visible = val
	}

	distort(force, intensity, seed, duration, ease) {

		if (!force) {
			if (this.backTimeline.isActive()) return
		}

		this.backTimeline.kill()
		this.backTimeline.clear()

		const queue = []

		this.material.uniforms.seed.value = seed
		this.material.uniforms.time.value = 0
		this.material.uniforms.intensity.value = intensity
		this.material.uniforms.back_index0.value = this.material.uniforms.back_index1.value

		queue.push(this.menu.tween(this.material.uniforms.time, duration, {ease:ease, value:1.0}))

		this.backTimeline.add(queue)
	}

	hover(duration, ease) {
		const queue = []

		this.textTimeline.kill()
		this.textTimeline.clear()

		this.material.uniforms.hover_t.value = 0.0
		this.material.uniforms.hover_o.value = 1.0
		this.material.uniforms.hover_s.value = Math.random()

		queue.push(this.menu.tween(this.material.uniforms.hover_t, duration * 1.0, {delay:duration * 0.0, value:1.0, ease:ease}))
		queue.push(this.menu.tween(this.material.uniforms.hover_o, duration * 0.2, {delay:duration * 0.8, value:0.0, ease:ease}))

		this.textTimeline.add(queue).call(() => {
			this.material.uniforms.hover_t.value = 0.0
		})
	}

	changeBackTexture(force, intensity, seed, index, duration, ease) {

		if (!force) {
			if (this.backTimeline.isActive()) return
		}

		this.backTimeline.kill()
		this.backTimeline.clear()

		const queue = []

		this.material.uniforms.seed.value = seed
		this.material.uniforms.time.value = 0
		this.material.uniforms.intensity.value = 1.0
		this.material.uniforms.back_index0.value = this.material.uniforms.back_index1.value
		this.material.uniforms.back_index1.value = index

		queue.push(this.menu.tween(this.material.uniforms.time, duration, {ease:ease, value:1}))

		this.backTimeline.add(queue)
	}
}