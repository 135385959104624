
import React from 'react'

import $ from 'jquery'

import styles from './index.module.scss'


class Scrolltop extends React.Component {

	scroll() {
		$('html,body').stop().animate({scrollTop:0}, 750, 'ioX4')
	}

	render() {
		return (
			<div className={styles.scrolltop}>
				<button onClick={this.scroll.bind(this)}>
					<div><span><img src="/assets/img/scrolltop.svg" alt="" /></span></div>
				</button>
			</div>
		)
	}
}


export default Scrolltop