
import axiosBase from 'axios'

const axios = axiosBase.create({
	baseURL : process.env.REACT_APP_API_ENDPOINT,
	headers : {
		'Content-Type': 'application/x-www-form-urlencoded'
	},
	responseType: 'json'
})

export default function(progress) {

	return axios.get('/static/menu.json?t='+parseInt(+new Date()/1000), {

	}).then((response) => {
		return response.data

	}).then((payload) => {
		return { payload }

	}).catch((error) => {
		return { error }
	})
}