
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/loader/actions'

import MenuContent from './content'
import MenuRouter  from './router'


class Menu extends React.Component {

	componentDidMount() {
		this.props.start()
	}

	render() {
		return (
			<MenuRouter>
				<MenuContent />
			</MenuRouter>
		)
	}
}

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {
		start : () => dispatch(actions.start())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)