
import * as THREE from 'three'

import MenuObject from './menuobject'

import Image  from './image'
import mixer1 from '../shader/mixer1.glsl'
import mixer5 from '../shader/mixer5.glsl'


export default class ImageSet extends MenuObject {

	constructor(menu, param, textures, index, parent=null) {
		super(menu)

		this.textures = textures

		this.index = index

		this.isFirstChild = (parent && index === 0)

		this.isLastChild = (parent && index === textures.length - 1)


		// テキスト画像と背景
		this.imageU = new Image(menu, [param.menu_image.texture], textures, parent ? mixer1 : mixer5)
		this.imageD = new Image(menu, [param.menu_image.texture], textures, parent ? mixer1 : mixer5)
		this.object.add(this.imageU.mesh)
		this.object.add(this.imageD.mesh)

		this.imageU.material.uniforms.hover_i.value = 0
		this.imageD.material.uniforms.hover_i.value = 1
		
		// 開いたときに出る線
		this.lineU = this.createLineMesh()
		this.lineD = this.createLineMesh()
		this.object.add(this.lineU)
		this.object.add(this.lineD)

		// 子メニュー
		if (param.children) {

			const textures = param.children.map(childParam => childParam.back_image.texture)

			this.children = param.children.map((childParam, i) => new ImageSet(menu, childParam, textures, i, this))
		} else {
			this.children = []
		}

		this.selected = -1

		this.height = 0

		// アニメーション用変数
		this.props = {
			pageGap : 0.0,
			marginU : this.CHILD_HEIGHT / -2,
			marginD : this.CHILD_HEIGHT / -2
		}
	}

	get HEIGHT_U() {
		if (this.menu.isSP) {
			return 91 / 2048
		} else {
			return 91 / 2048
		}
	}

	get HEIGHT_D() {
		if (this.menu.isSP) {
			return 119 / 2048
		} else {
			return 119 / 2048
		}
	}

	get HEIGHT() {
		return this.HEIGHT_U + this.HEIGHT_D
	}

	get MARGIN() {
		if (this.menu.isSP) {
			return 30 / 2048
		} else {
			return 30 / 2048
		}
	}

	get OFFSET() {
		if (this.menu.isSP) {
			return (119 - 91) / 2048
		} else {
			return (119 - 91) / 2048
		}
	}

	get CHILD_HEIGHT() {
		return this.children.length * this.HEIGHT + (this.children.length > 0 ? this.OFFSET * 2 : 0)
	}

	get opacity() {
		return this.imageU.material.uniforms.text_opacity.value
	}

	set opacity(val) {
		this.imageU.material.uniforms.text_opacity.value = val
		this.imageD.material.uniforms.text_opacity.value = val
	}

	createLineMesh() {
		const geometry = new THREE.PlaneGeometry()
		const material = new THREE.MeshBasicMaterial({color:0x000000})
		return new THREE.Mesh(geometry, material)
	}

	setSize(cw, ch, cr, bw) {
		super.setSize(cw, ch, cr, bw)

		const hu = this.HEIGHT_U
		const hd = this.HEIGHT_D
		const hh = this.HEIGHT
		const ho = this.OFFSET
		const top = 0.5 + (this.textures.length * this.HEIGHT / 2 - hu) * bw / cw

		this.imageU.mesh.scale.x = 2 * cr
		this.imageD.mesh.scale.x = 2 * cr

		this.imageU.mesh.scale.y = bw * hu
		this.imageD.mesh.scale.y = bw * hd

		if (this.isFirstChild) {
			this.imageU.mesh.scale.y += bw * ho
		}
		if (this.isLastChild) {
			this.imageD.mesh.scale.y += bw * ho
		}

		this.imageU.material.uniforms.text_mapping_ratio.value.x = this.imageU.mesh.scale.x / bw
		this.imageU.material.uniforms.text_mapping_ratio.value.y = this.imageU.mesh.scale.y / bw / hh

		this.imageU.material.uniforms.text_mapping_offset.value.x = - (cr - cw / 2) / bw
		this.imageU.material.uniforms.text_mapping_offset.value.y = hd / hh

		this.imageD.material.uniforms.text_mapping_ratio.value.x = this.imageU.mesh.scale.x / bw
		this.imageD.material.uniforms.text_mapping_ratio.value.y = this.imageD.mesh.scale.y / bw / hh

		this.imageD.material.uniforms.text_mapping_offset.value.x = - (cr - cw / 2) / bw
		this.imageD.material.uniforms.text_mapping_offset.value.y = 0


		this.imageU.material.uniforms.back_mapping_ratio.value.x = this.imageU.mesh.scale.x / cw
		this.imageU.material.uniforms.back_mapping_ratio.value.y = this.imageU.mesh.scale.y / cw

		this.imageD.material.uniforms.back_mapping_ratio.value.x = this.imageD.mesh.scale.x / cw
		this.imageD.material.uniforms.back_mapping_ratio.value.y = this.imageD.mesh.scale.y / cw

		this.imageU.material.uniforms.back_mapping_offset.value.x = - (cr - cw / 2) / cw
		this.imageU.material.uniforms.back_mapping_offset.value.y = top - (this.index * hh) * bw / cw

		this.imageD.material.uniforms.back_mapping_offset.value.x = - (cr - cw / 2) / cw
		this.imageD.material.uniforms.back_mapping_offset.value.y = top - (this.index * hh + hd) * bw / cw


		if (this.isLastChild) {
			this.imageD.material.uniforms.text_mapping_offset.value.y -= ho / hh
			this.imageD.material.uniforms.back_mapping_offset.value.y -= ho * bw / cw
		}

		this.lineU.scale.x = 2 * cr
		this.lineU.scale.y = 1 * this.menu.resolution

		this.lineD.scale.x = 2 * cr
		this.lineD.scale.y = 1 * this.menu.resolution

		this.children.forEach(child => child.setSize(cw, ch, cr, bw))
	}


	next(val) {
		const y0 = val

		this.imageU.mesh.position.y = this.canvasH / 2 - y0 - this.imageU.mesh.scale.y / 2
		
		// 文字上側の下端y座標
		const yU = y0 + this.imageU.mesh.scale.y

		// 子要素の上端y座標
		const yUc = yU + this.baseWidth * this.props.marginU

		// 子要素の下端y座標
		const yDc = this.children.reduce((y, child) => child.next(y), yUc)

		// 文字下側の上端y座標
		const yD = yDc + this.baseWidth * this.props.marginD + this.caclGap()

		const visibility = yD - yU > 0.1

		this.height = yD - yU

		this.imageD.mesh.position.y = this.canvasH / 2 - yD - this.imageD.mesh.scale.y / 2

		this.lineU.visible = visibility
		this.lineD.visible = visibility
		this.lineU.position.y  = this.imageU.mesh.position.y - this.imageU.mesh.scale.y / 2
		this.lineD.position.y  = this.imageD.mesh.position.y + this.imageD.mesh.scale.y / 2

		this.children.forEach((child) => {
			child.object.visible = visibility
			child.imageU.visible = true
			child.imageD.visible = true
		})

		// 上のメニューに隠れて見えない要素は描画しない
		if (visibility && this.children.length > 0) {
			for (let i=0, h=this.OFFSET; i<this.children.length; i++) {
				h += this.HEIGHT_U
				if (h >= -this.props.marginU) { break } else { this.children[i].imageU.visible = false }
				h += this.HEIGHT_D
				if (h >= -this.props.marginU) { break } else { this.children[i].imageD.visible = false }
			}
			for (let i=this.children.length-1, h=this.OFFSET; i>=0; i--) {
				h += this.HEIGHT_D
				if (h >= -this.props.marginD) { break } else { this.children[i].imageD.visible = false }
				h += this.HEIGHT_U
				if (h >= -this.props.marginD) { break } else { this.children[i].imageU.visible = false }
			}
		}
		

		// 次のy座標
		return yD + this.imageD.mesh.scale.y
	}

	caclGap() {
		return this.props.pageGap * (this.canvasW - 2 * this.baseWidth * (this.HEIGHT_D + this.MARGIN))
	}


	selectChild(index, duration, ease) {
		const queue = []

		this.selected = index
		
		this.children.forEach((child, i) => {
			// 子メニューを閉じる
			queue.push(child.closeSubmenu(duration, ease))

			// 選択した項目以外の文字をフェードアウト
			if (index === i) {
				queue.push(this.menu.tween(child, duration, {ease:ease, opacity:1}))
			} else {
				queue.push(this.menu.tween(child, duration, {ease:ease, opacity:0}))
			}
		})

		queue.push(this.menu.tween(this.props, duration, {
			ease    : ease,
			pageGap : 0,
			marginU : this.children.reduce((h, child, i) => i < index ? h - child.HEIGHT : h, 0),
			marginD : this.children.reduce((h, child, i) => i > index ? h - child.HEIGHT : h, -this.OFFSET),
		}))

		return queue
	}

	openSubmenu(duration, ease) {
		const queue = []

		this.children.forEach((child, i) => {
			queue.push(this.menu.tween(child, duration, {ease:ease, opacity:1}))
		})

		queue.push(this.menu.tween(this.props, duration, {
			ease    : ease,
			pageGap : 0,
			marginU : 0,
			marginD : 0,
		}))

		return queue
	}

	closeSubmenu(duration, ease) {
		const queue = []

		this.children.forEach((child, i) => {
			queue.push(this.menu.tween(child, duration, {ease:ease, opacity:1}))
		})

		let marginU = this.CHILD_HEIGHT / -2
		let marginD = this.CHILD_HEIGHT / -2

		if (this.selected !== -1) {
			marginU = this.children.reduce((h, child, i) => i < this.selected ? h - child.HEIGHT : h, -this.HEIGHT_U-this.OFFSET)
			marginD = this.children.reduce((h, child, i) => i > this.selected ? h - child.HEIGHT : h, -this.HEIGHT_D-this.OFFSET)
		}

		queue.push(this.menu.tween(this.props, duration, {
			ease    : ease,
			pageGap : 0,
			marginU : marginU,
			marginD : marginD,
		}))

		this.children.forEach((child) => {
			queue.push(child.closeSubmenu(duration, ease))
		})

		return queue
	}

	openGap(duration, ease) {
		const queue = []

		queue.push(this.menu.tween(this.props, duration, {
			ease    : ease,
			pageGap : 1,
		}))

		return queue
	}

	changeTexture(index, duration, ease) {
		const seed = Math.random()

		this.children.forEach((child) => {
			child.imageU.changeBackTexture(true, 1.0, seed, index, duration, ease)
			child.imageD.changeBackTexture(true, 1.0, seed, index, duration, ease)
		})
	}
}