
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './index.module.scss'


const MoreLink = (props) => (
	<div className={styles.morelink}>
		<div>
			<NavLink exact to="/">
				{props.device === 'sp' ? (
					<img src="/assets/img/more@sp.png" alt="MORE" />
				) : (
					<img src="/assets/img/more@pc.png" alt="MORE" />
				)}
			</NavLink>
		</div>
	</div>
)

export default MoreLink