
import * as THREE from 'three'

import Image from './image'

import MenuObject from './menuobject'

import mixer5 from '../shader/mixer5.glsl'


export default class White extends MenuObject {

	constructor(menu, back_texture) {
		super(menu)

		this.image = new Image(menu, [new THREE.Texture()], [back_texture], mixer5)

		this.image.material.uniforms.text_index0.value = -1

		this.object.add(this.image.mesh)
	}

	setSize(cw, ch, cr, bw) {
		super.setSize(cw, ch, cr, bw)

		const size = Math.max(cw, ch)

		this.image.mesh.scale.x = 2 * cr
		this.image.mesh.scale.y = 2 * cr

		this.image.material.uniforms.back_mapping_ratio.value.x = 2 * cr / size
		this.image.material.uniforms.back_mapping_ratio.value.y = 2 * cr / size
		this.image.material.uniforms.back_mapping_offset.value.x = - (cr -size / 2) / size
	}

	draw(y, dir) {
		this.object.position.y = this.canvasH / 2 - y + this.image.mesh.scale.y * dir / 2
	}
}