
import axiosBase from 'axios'

const axios = axiosBase.create({
	baseURL : process.env.REACT_APP_API_ENDPOINT,
	headers : {
		'Content-Type': 'application/x-www-form-urlencoded'
		//,'X-Requested-With': 'XMLHttpRequest'
	},
	responseType: 'json'
})

const cache = new Map()


export default function fetchPage(path) {

	let url

	if (path.match(/\/$/)) {
		url = `/static/page${path}index.json?v=1.1`
	} else {
		url = `/static/page${path}.json?v=1.1`
	}

	if (cache.has(url)) {
		return Promise.resolve({ payload : cache.get(url) })
	} else {
		return axios.get(url, {

		}).then((response) => {
			return response.data

		}).then((payload) => {
			cache.set(url, payload)
			return { payload }

		}).catch((error) => {
			return { error }
		})
	}
}