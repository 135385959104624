
import React from 'react'

import { withRouter } from 'react-router-dom'

import { connect } from 'react-redux'

import Polaris from 'lib/Polaris'

import actions from 'states/notice/actions'

import Item from './item'

import styles from './index.module.scss'


class Notices extends React.Component {

	componentDidUpdate(prevProps, prevState) {

		// メニュー用データのロード待機
		if (this.props.menudata !== prevProps.menudata && this.props.menudata) {
			this.load()
		}

		// 告知の状態変化を監視
		if (this.props.nindex !== prevProps.nindex) {
			this.forceUpdate()
		}

		// ページ遷移ですべて閉じる
		if (this.props.pathname !== prevProps.pathname && this.props.pathname !== '/') {
			this.closeAll()
		}
	}


	load() {
		if (this.props.pathname === '/') {

			const x = Polaris.util.rand(5, 30) / 100
			const y = Polaris.util.rand(6, 25) / 100

			const notices = this.props.menudata.notices.map((props, i) => {
				props.w = props.notice_image_pc.width
				props.h = props.notice_image_pc.height
				props.x = x + i * 0.05
				props.y = y - i * 0.05
				props.z = this.props.menudata.notices.length - i
				props.closed = false

				return props
			})

			this.props.ready(notices)
		}
	}

	closeAll() {
		this.props.notices.forEach((notice, i) => {
			setTimeout(() => {
				this.props.close(i)
			}, i*200)
		})
	}

	render() {
		if (this.props.device !== 'sp') return null

		return (
			<div className={styles.notices}>
				<ul>
					{this.props.notices.map((notice, i) => (
					<Item key={i} index={i} {...notice}>
						{this.props.device === 'sp' ? (
							<img src={notice.notice_image_sp.src} alt={notice.notice_image_sp.alt} />
						) : (
							<img src={notice.notice_image_pc.src} alt={notice.notice_image_pc.alt} />
						)}
					</Item>
					))}
				</ul>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		device   : state.device.mode,
		nindex   : state.notice.index,
		notices  : state.notice.notices,
		pathname : state.router.location.pathname,
		menudata : state.loader.payload,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		ready : (notices) => dispatch(actions.ready(notices)),
		close : (index) => dispatch(actions.close(index)),
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notices))