
export default `

	if (0.0 < time && time < 1.0) {
		//float z = getNoise5(vec3(back_u + seed, back_v + seed, time * 0.1));
		float z = getNoise7(vec2(back_u + seed, back_v + seed), time * 0.1);
		float c = 1.0 * z;
		
		vec2 q0 = intensity * vec2(c, c) * (time);
		vec2 q1 = intensity * vec2(c, c) * (1.0 - time);

		vec4 c0 = map_back(back_index0, back_u + q0.x, back_v + q0.y);
		vec4 c1 = map_back(back_index1, back_u + q1.x, back_v + q1.y);

		back_color = mix(c0, c1, time);
	} else {
		back_color = map_back(back_index1, back_u, back_v);
	}
`