
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './index.module.scss'


const Side = (props) => (
	<React.Fragment>
		<NavLink exact to={props.link} className={styles.prev}  />
		<NavLink exact to={props.link} className={styles.next}  />
	</React.Fragment>
)

export default Side