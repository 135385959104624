
import React from 'react'

import Polaris from 'lib/Polaris'

import { connect } from 'react-redux'

import deviceActions from 'states/device/actions'

import styles from './wrapper.module.scss'


class Wrapper extends React.Component {

	wrapper = React.createRef()


	componentDidMount() {
		this.startDeviceChecker()
		this.startScrollChecker()
	}


	startDeviceChecker() {
		const computedStyle = window.getComputedStyle(this.wrapper.current, '::before');

		let type = null;

		Polaris.util.onResize(() => {

			const _type = computedStyle.getPropertyValue('font-family');

			if (type !== _type) {
				type = _type;
				if (type === 'pc') this.props.devicePC();
				if (type === 'tb') this.props.deviceTB();
				if (type === 'sp') this.props.deviceSP();
			}
		})
	}


	startScrollChecker() {
		this.scrollTop = 0
		this.scrollMap = new Map()

		Polaris.util.onScroll((t, b) => {
			this.scrollTop = t
		})
	}


	shouldComponentUpdate(nextProps) {

		// パス変化時に前のパスをキーにスクロール量を保存
		if (nextProps.path !== this.props.path) {
			this.scrollMap.set(this.props.path, this.scrollTop)
		}

		// レンダリング完了後、スクロール量を設定
		if (nextProps.ready !== this.props.ready && nextProps.ready) {

			if (nextProps.reason === 'PUSH') {
				window.scrollTo(0, 0)
			}

			if (nextProps.reason === 'POP') {
				window.scrollTo(0, this.scrollMap.get(this.props.path))
			}
		}

		return true
	}


	render() {
		return (
			<div ref={this.wrapper} className={styles.wrapper}>
				{this.props.children}
			</div>
		)
	}
}


function mapStateToProps(state) {
	return {
		path   : state.page.path,
		reason : state.page.reason,
		ready  : state.menu.ready,
	}
}


function mapDispatchToProps(dispatch) {
	return {
		devicePC : () => dispatch(deviceActions.changedPC()),
		deviceTB : () => dispatch(deviceActions.changedTB()),
		deviceSP : () => dispatch(deviceActions.changedSP()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
